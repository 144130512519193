import styled from 'styled-components';

const SalesInvoiceStyled = styled.div`
.display_none_print{
display:none;
}
  .sales-invoice-create {
    .dialog-upperpart {
      .error-msg {
        padding: 5px 12px;
        border: 1px solid #da1414;
        border-radius: 4px;
        background-color: #feefef;
        margin-bottom: 10px;
        label {
          color: #da1414;
          font-size: 14px;
          line-height: 21px;
        }
      }
      .upc-value {
        font-size: 12px;
      }
      .row_two{
        .select-css{
          margin-top:6px;
        }
      }
    }
    .total-section-wrapper .sub-total-wrap {
      .default-left-margin-9 {
        .mdc-textfield {
          height: 25px;
        }
      }
    }
  }
  .outstanding-due{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    .negative{
      color: red;
    }
  }
`;

export default SalesInvoiceStyled;
