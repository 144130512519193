import React, { Component } from 'react';
import { connect } from 'react-redux';
import tableConfig from './config';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { Grid, Cell, Icon } from '../../../components/BillingMDC';
import * as downloadService from '../../common/download.service';
import TableHeader from '../../../components/TableHeader/TableHeader';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import { STOCK_VALUATION_REPORT_CONFIG } from '../../common/domain.config';
import { groupPrintDataSet } from '../../common/print.service';
import StockValuationStyled from './StockValuationStyled';

class StockValuationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
        },
      },
      dataList: [],
      printData: [],
      data: {
        list: [],
        total: 0,
      },
      filterDisable: true,
      activeView: tableConfig.ledgerTypeViewOptions[1].label,
    };

    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadPrintData = async () => {
    const { queryParameters, data } = this.state;
    const { getListUpdate } = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
    query.pagination.page = 1;
    await getListUpdate(query)
      .then(response => {
        const printData = response.data || [];
        this.setState({ printData }, () => {
          this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage('Error while fetching print data set');
      });
  };

  /** get data for grid, adjust loading flag */
  loadTableData = async (print = false) => {
    const { queryParameters, activeView, data } = this.state;
    const { getListUpdate } = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = data?.total;
    query.pagination.page = 1;
    this.setState({ filterDisable: true });
    const extraData = `&valuation_type=${activeView}`;
    await getListUpdate(print ? { query, extraQuery: extraData } : { query: queryParameters, extraQuery: extraData })
      .then(response => {
        data.list = response.data || [];
        data.total = response.total || 0;
        this.setState({ data, filterDisable: false }, () => {
          print && this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ filterDisable: true });
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handlePrintClick = async () => {
    const self = this;
    await this.loadTableData(true);
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.loadTableData();
      self.setState({ printButtonClicked: false });
    };
  };
  groupPrintDataSetNew() {
    const { data } = this.state;
    const { company } = this.props;
    const printInfoBill = { ...company, printInfo: { ...company.printInfo, batchEnabled: false } } || {};
    const orders = clone(data?.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }

  handleViewChange = activeView => {
    this.setState({ activeView }, () => {
      this.loadTableData();
    });
  };

  handleRowClick = data => {
    // const { queryParameters } = this.state;
    // const { storeStockLedger } = this.props;
    // storeStockLedger(setStockLedgerData({ row: data, date: queryParameters.date }));
    // history.push(`/${STOCK_LEDGER_DETAILS}/${data.skuId}`);
  };
  handleDownloadClick = (type, label) => {
    const { downloadList } = this.props;
    const { queryParameters, activeView } = this.state;
    const { DOWNLOAD_CONST } = tableConfig;
    let extraQueryString = '';
    switch (label) {
      case 'stock_valuation_dlp':
        extraQueryString = `&valuation_type=${DOWNLOAD_CONST.DLP}`;
        break;
      case 'stock_valuation_fifo':
        extraQueryString = `&valuation_type=${DOWNLOAD_CONST.FIFO}`;
        break;
      case 'stock_valuation_weighted':
        extraQueryString = `&valuation_type=${DOWNLOAD_CONST.WEIGHTED_AVERAGE}`;
        break;
      case 'stock_valuation_analysis':
        extraQueryString = `&valuation_type=${DOWNLOAD_CONST.FIFO_ANALYSIS}`;
        break;
      default:
        extraQueryString = `&valuation_type=${activeView}`;
        break;
    }
    downloadList({
      type,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };
  handleResetFilter = () => {
    this.setState({ activeView: tableConfig.ledgerTypeViewOptions[0].label });
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id: [],
      },
    });
  };

  render() {
    const { queryParameters, data, display, snack, printButtonClicked, dataList, date, filterDisable, activeView } =
      this.state;
    const { serverResponseWaiting, company } = this.props;
    return (
      <StockValuationStyled className="stock-value-report ">
        <div className={printButtonClicked ? 'no-print' : 'display-block'}>
          <PageView
            domainConfig={STOCK_VALUATION_REPORT_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            display={display}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.basePaginationService.clearSearchText}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            data={data}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleRowClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handlePrintClick={this.handlePrintClick}
            filterDisable={filterDisable}
            viewOptions={{
              list: tableConfig.ledgerTypeViewOptions,
              handler: this.handleViewChange,
              selectedValue: activeView,
              title: 'Valuation Type',
            }}
          />
        </div>

        <div className={printButtonClicked ? 'display-block portrait-type' : 'no-print'}>
          <div>
            {dataList.map((chunk, key) => {
              const { list = [], page = 0, remainingLines = [] } = chunk;
              return (
                <div className="print-body zoom-reduce">
                  <div className="pickList-body card-body">
                    <header>
                      <Grid className="flex">
                        <Cell col={2} tablet={2} phone={2} className="left">
                          <div className="header-title">{company.title}</div>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Address: </span>
                            <span>{company.address}</span>
                          </span>
                          <span className="header-title-content">
                            <span className="active-opacity-text">Phone: </span>
                            <span>{company.phone}</span>
                          </span>
                          <span className="no-margin-bottom header-title-content">
                            <span className="active-opacity-text">PAN No: </span>
                            <span>{company.panNumber}</span>
                          </span>
                        </Cell>
                        <Cell col={4} tablet={4} phone={4} className="center">
                          <div className="center-align">
                            <h2>{STOCK_VALUATION_REPORT_CONFIG.title}</h2>
                            <div className="default-margin-top-3">
                              <span className="header-title-content no-margin-bottom">
                                <span className="active-opacity-text">
                                  Report from
                                  <span className="date-rendered">
                                    {` ${filterUtil.formatToReadableDate(
                                      queryParameters.date.start,
                                    )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                                  </span>
                                </span>
                              </span>
                              <span className="header-title-content"></span>
                            </div>
                          </div>
                        </Cell>
                        <Cell col={2} tablet={2} phone={2} className="right">
                          <div className="header-menu-right">
                            <span>
                              <span className="">Date: </span>
                              <span>{filterUtil.formatToReadableDate(date)}</span>
                            </span>
                          </div>
                        </Cell>
                      </Grid>
                    </header>
                    <div className="fixed-table-wrapper">
                      <table className="pdf-table">
                        <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                        {tableConfig.getTableBody({ dataList: list, remainingLines: remainingLines })}
                      </table>
                    </div>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={page} totalPage={dataList.length} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StockValuationStyled>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeStockLedger: action => dispatch(action),
  };
}

const StockLedgerWithState = withBaseState(StockValuationReport);

export default connect(null, mapDispatchToProps)(StockLedgerWithState);
