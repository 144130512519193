import React from 'react'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
  Button,
} from '../../../../components/BillingMDC';

const WarningDialog = ({actionType,onModalSubmit,onModalClose,readModalOpen}) => {
  return (
    <Dialog
      open={readModalOpen}
      onClose={() => {
        handleModalClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>Confirmation Dialog</DialogTitle>
      </DialogHeader>
      <DialogBody>
      <div className='dialog-upperpart'>Are you sure you want to update per unit rate?</div>
      </DialogBody>
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            onModalClose();
          }}
        >
        Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            onModalSubmit();
          }}
        >
        Ok
        </Button>
      </DialogFooter>
    </Dialog>
  )
};

export default WarningDialog;
