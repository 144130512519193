import { fixedFloatAndCommas } from '../../../../utils/conversion';

const getTableData = (dataList, companyData) => {
  let tableDataRows = [];
  const getUOM = (list, field) => list?.uom_list?.find(a => a?.id === list?.uomId)[field];
  console.log('checking', getUOM)
  if (dataList && dataList.length > 0) {
    tableDataRows = dataList?.map((data, index) => {
      let columnRow = [
        index + 1,
        data.sku ?? '',
        (data.quantity ?? 0) / getUOM(data, 'pcs') || data?.quantity || '',
        // (data.quantity ?? 0) / getUOM(data, 'pcs') ?? '',
        getUOM(data, 'label') ?? 'Pcs',
        // isAbbreviatedInvoice
        //   ? fixedFloatAndCommas(data.uomRate * data.exciseFactor * (1 + data.vatPercent / 100)) ?? '0' :
        fixedFloatAndCommas(data.uomRate * data.exciseFactor * (1 + data.vatPercent / 100)) ?? '0',
        fixedFloatAndCommas(data.amount) ?? '0',
        fixedFloatAndCommas(data.promotionDiscount) ?? '0',
        fixedFloatAndCommas(data.netAmount) ?? '0',
      ];
      companyData?.printInfo?.batchEnabled && columnRow.splice(2, 0, data.batch ?? '');
      return columnRow;
    });
  }
  return {
    tableDataRows,
  };
};

const getFilteredData = (dataList, companyData, headerData, totalDatas) => {
  const tableData = getTableData(dataList, companyData);
  const agentInfo = [
    { title: 'SalesPerson', value: totalDatas?.agentInfo?.value ?? '' },
    { title: 'Sub-D Email', value: companyData?.email ?? '' },
  ];
  const copyOfPrintInfo = [...totalDatas?.printInfo];
  copyOfPrintInfo.pop();
  const printInfo = copyOfPrintInfo ?? [];
  const footerMiscellaneousList = [
    { data: printInfo, partition: 3 },
    { data: agentInfo, partition: 2 },
  ];
  const copyOfMiscellaneous = [...headerData.dataMiscellaneousList];
  copyOfMiscellaneous.pop();
  const getInvoicedata = copyOfMiscellaneous.shift();

  const arrangeByDataOrder = (array, key) => {
    const dataOrder = {
      'customer name': 1,
      'pan no': 2,
      'address': 3,
      'phone number': 4,
    };

    return [...array]?.sort((a, b) => {
      return dataOrder[a[key]?.toLowerCase()] - dataOrder[b[key]?.toLowerCase()];
    });
  };

  const miscellaneousData = arrangeByDataOrder(copyOfMiscellaneous, 'title');

  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
    panNo: companyData?.panNo || companyData?.panNumber || '',
    // email: companyData?.email ?? '',
    title: headerData?.title ?? '',
    printCount: headerData?.printCountInfo?.value ?? '',
    printBillCounter: companyData?.printInfo?.count ?? '',
    printCountExist: totalDatas?.printCountExist,
    date: headerData?.date ?? '',
    miti: headerData?.miti ?? '',
    paymentDetail: headerData?.paymentDetail ?? '',
    dataMiscellaneousList: miscellaneousData?.length > 0 ? miscellaneousData : [],
    invoicedata: getInvoicedata,
    footerMiscellaneousList: footerMiscellaneousList ?? [],
    tableDataRows: [...tableData?.tableDataRows] ?? [],
    tableFooterData: totalDatas,
  };
};

const getPrintData = (dataList, companyData, headerData, totalDatas) => {
  const ledgerData = getFilteredData(dataList, companyData, headerData, totalDatas);
  return ledgerData;
};

export default getPrintData;
