import { fixedFloatAndCommas, roundAndCommas } from '../../../../utils/conversion';
import { formatToReadableDate } from '../../../../utils/filterUtil';

const getTableData = ledgerList => {
  let tableDataTotalDebit = 0;
  let tableDataTotalCredit = 0;
  let tableDataTotalBalance = 0;
  let tableDataRows = [];
  const copyOfList = [...ledgerList.list];
  let totalDatas = copyOfList.pop();
  // console.log('copyOfList',copyOfList)
  if (copyOfList?.length > 0) {
    tableDataRows = copyOfList?.map((data, index) => {
      tableDataTotalDebit = tableDataTotalDebit + data.debit;
      tableDataTotalCredit = tableDataTotalCredit + data.credit;
      tableDataTotalBalance = tableDataTotalBalance + data.balance;

      return [
        index + 1,
        data.date ? formatToReadableDate(data.date) : '',
        data.mitiTitle ?? '',
        data.voucherNumber ?? '',
        // index === 0 ? 'Opening Transaction' : data.name?.trim() ?? '',
        data.narration ?? '',
        fixedFloatAndCommas(data.debit) ?? '0',
        fixedFloatAndCommas(data.credit) ?? '0',
        roundAndCommas(Math.abs(data.balance)) ?? '0',
        data.balance > 0 ? ' Dr' : ' Cr',
      ];
    });
  }

  return {
    tableDataTotalDebit: fixedFloatAndCommas(totalDatas?.debit ?? '').toString(),
    tableDataTotalCredit: fixedFloatAndCommas(totalDatas?.credit ?? '').toString(),
    tableDataTotalBalance: roundAndCommas(Math.abs(totalDatas?.balance ?? '')).toString(),
    tableDataTotalDebitORCredit: tableDataTotalBalance > 0 ? ' Dr' : ' Cr',
    tableDataRows,
    
  };
};

const getFilteredData = (ledgerList, companyData, ledgerDetails) => {
  const tableData = getTableData(ledgerList);

  const reportPerioid = {
    reportPeriod: true,
    value: ledgerDetails?.date ?? ''
  };

  return {
    companyName: companyData?.title ?? '',
    address: companyData?.address ?? '',
    phone: companyData?.phone ?? '',
    panNo: companyData?.panNo || companyData?.panNumber || '',
    email: companyData?.email ?? '',
    title: ledgerDetails?.detail?.name ?? '',
    statement: 'Statement of ledger',
    date: reportPerioid ?? '',
    tableDataRows: tableData?.tableDataRows ?? [],
    tableFooterData: {
      tableDataTotalDebit: tableData?.tableDataTotalDebit ?? '',
      tableDataTotalCredit: tableData?.tableDataTotalCredit ?? '',
      tableDataTotalBalance: tableData?.tableDataTotalBalance ?? '',
      tableDataTotalDebitORCredit: tableData?.tableDataTotalDebitORCredit ?? '',
    },
  };
};

const getLedgerDetailData = (ledgerList, companyData, ledgerDetails) => {
  const ledgerData = getFilteredData(ledgerList, companyData, ledgerDetails);

  return ledgerData;
};

export default getLedgerDetailData;
