import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { turnicateString } from '../../../utils/conversion';

const printLedgerDetailPdfContent = (
  configData,
  printData,
  orientation = 'portrait',
  variation = 'normal',
  openOnNewTab = false,
) => {
  const doc = new jsPDF({
    orientation: orientation,
    format: 'a4',
  });

  const Primary_Font_Size = 9;
  const Secondary_Font_Size = 8;
  const increaseSpaceByFontSize = intialHeight => {
    return (intialHeight / 7) * Primary_Font_Size;
  };

  // make the footer miscellaneous height dynamic
  let incrementFooterMiscellaneousBy = increaseSpaceByFontSize(5);
  printData?.footerMiscellaneousList?.map((item, index) => {
    incrementFooterMiscellaneousBy = (index + 2) * increaseSpaceByFontSize(5);
  });

  // on 7 fontszie, 3 spacing was added between text
  // so by doing below formula, we get the spacing according to Primary_Font_Size value
  const ADD_SPACE_BY = Math.ceil((3 / 7) * Primary_Font_Size);
  const OUTER_CORDINATE_X = 8;
  const OUTER_CORDINATE_Y = 8;
  const Decrease_Width_BY = OUTER_CORDINATE_X * 2;
  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
  const SECTION_WIDTH = PAGE_WIDTH - Decrease_Width_BY;

  // make the second header height dynamic
  const getHeaderWidth = (SECTION_WIDTH - 3) / printData?.dataMiscellaneousList?.length;
  let highestLength = 0;
  printData?.dataMiscellaneousList.map(data => {
    doc.setFontSize(Primary_Font_Size);
    doc.setFont('Helvetica', 'bold');
    const splitTitle = doc.splitTextToSize(data.value ?? '', getHeaderWidth);
    highestLength = splitTitle?.length > highestLength ? splitTitle?.length : highestLength;
  });

  const HEADER_RECTANGLE_HEIGHT = printData?.excise ? increaseSpaceByFontSize(24.5) : increaseSpaceByFontSize(21.5);
  let SECOND_HEADER_RECTANGLE_HEIGHT = increaseSpaceByFontSize(9) + highestLength * increaseSpaceByFontSize(3);
  const FOOTER_RECTANGLE_HEIGHT =
    (printData?.tableFooterData?.printFooterNote ? increaseSpaceByFontSize(32.5) : increaseSpaceByFontSize(23.5)) +
    incrementFooterMiscellaneousBy;
  const BORDER_COLOR = '#272833';
  // global font setting
  doc.setFont('Helvetica', '');

  const setTitleCenter = (title, alignTitleCenter, startTopCordinate) => {
    doc.text(title, alignTitleCenter, startTopCordinate, { align: 'left' });
  };

  const setTitle = (titleName, titleSize, startTopCordinate, leftSpace = false, concatText = '') => {
    const title = titleName;
    const fontSize = doc.setFontSize(titleSize);
    const middleCordinate = PAGE_WIDTH / 2;
    const alignTextCenter = (fontSize.getTextWidth(title) + fontSize.getTextWidth(concatText)) / 2;
    const alignTitleCenter = middleCordinate - alignTextCenter + (leftSpace ? fontSize.getTextWidth(concatText) : 0);
    setTitleCenter(title, alignTitleCenter, startTopCordinate);
  };

  const addTopHeaderSection = headerTitle => {
    // top Header
    doc.setLineWidth(0.2);
    doc.setDrawColor(BORDER_COLOR);
    doc.rect(OUTER_CORDINATE_X, OUTER_CORDINATE_Y, SECTION_WIDTH, HEADER_RECTANGLE_HEIGHT);
    // doc.rect(OUTER_CORDINATE_X, OUTER_CORDINATE_Y, SECTION_WIDTH, PAGE_HEIGHT - 22);
    // left align section
    // doc.setFontSize(Primary_Font_Size + 2);
    // doc.setFont('Helvetica', 'bold');
    // doc.text(`${printData?.companyName ?? ''}`, OUTER_CORDINATE_X + 3, OUTER_CORDINATE_Y + 10 + ADD_SPACE_BY);
    // // to remove bold font style
    // doc.setFont('Helvetica', '');

    // doc.setFontSize(Primary_Font_Size);
    // doc.text(`Address: ${printData?.address}${printData?.address}`, OUTER_CORDINATE_X + 3, OUTER_CORDINATE_Y + 15 + ADD_SPACE_BY);
    // doc.setFontSize(Primary_Font_Size);
    // doc.text(`Phone: ${printData?.phone}`, OUTER_CORDINATE_X + 3, OUTER_CORDINATE_Y + 15 + ADD_SPACE_BY * 2);
    // doc.setFontSize(Primary_Font_Size);
    // doc.text(`Phone: ${printData?.panNo}`, OUTER_CORDINATE_X + 3, OUTER_CORDINATE_Y + 15 + ADD_SPACE_BY * 3);
    // // doc.setFontSize(7);
    // // doc.text(`Email: ${printData.email}`, 9, 26);
    // doc.setFontSize(Primary_Font_Size);
    // printData?.excise &&
    //   doc.text(`Excise No: ${printData?.excise}`, OUTER_CORDINATE_X + 3, OUTER_CORDINATE_Y + 15 + ADD_SPACE_BY * 4);

    // middle align section
    doc.setFont('Helvetica', 'bold');
    setTitle(`${printData?.companyName ?? ''}`, 12, OUTER_CORDINATE_Y + 2 + ADD_SPACE_BY);
    doc.setFont('Helvetica', '');
    setTitle(`Address: ${printData?.address ?? ''}`, Primary_Font_Size, OUTER_CORDINATE_Y + 2 + ADD_SPACE_BY * 2.2);
    setTitle(`Phone: ${printData?.phone ?? ''}`, Primary_Font_Size, OUTER_CORDINATE_Y + 2 + ADD_SPACE_BY * 3.2);
    setTitle(`PAN No: : ${printData?.panNo ?? ''}`, Primary_Font_Size, OUTER_CORDINATE_Y + 2 + ADD_SPACE_BY * 4.2);
    printData?.excise &&
      setTitle(`Excise No: ${printData?.excise ?? ''}`, Primary_Font_Size, OUTER_CORDINATE_Y + 2 + ADD_SPACE_BY * 5.2);
    doc.setFont('Helvetica', 'bold');
    const spaceForTitle = printData?.excise ? ADD_SPACE_BY * 6.5 : ADD_SPACE_BY * 5.5;
    setTitle(
      `${headerTitle}${printData?.printCount ? ` - ${printData.printCount.toString()}` : ''}`,
      12,
      OUTER_CORDINATE_Y + 2 + spaceForTitle,
    );

    // if (printData?.printCount) {
    //   const printCount = printData?.printCount?.toString();
    //   doc.setFont('Helvetica', '');
    //   setTitle(`Print Count:`, Primary_Font_Size, OUTER_CORDINATE_Y + 9.5 + ADD_SPACE_BY, 0, printCount);

    //   const printCountText = 'Print Count:';
    //   doc.setFont('Helvetica', 'bold');
    //   setTitle(printCount, Primary_Font_Size, OUTER_CORDINATE_Y + 9.5 + ADD_SPACE_BY, true, printCountText);
    // }

    // right align section
    doc.setFont('Helvetica', '');
    doc.setFontSize(Primary_Font_Size);
    doc.text(
      `Date : ${printData?.date}`,
      SECTION_WIDTH + (OUTER_CORDINATE_X - 3),
      OUTER_CORDINATE_Y + 6.8 + ADD_SPACE_BY,
      { align: 'right' },
    );
    doc.setFontSize(Primary_Font_Size);
    doc.text(
      `Miti : ${printData?.miti}`,
      SECTION_WIDTH + (OUTER_CORDINATE_X - 3),
      OUTER_CORDINATE_Y + 6.8 + ADD_SPACE_BY * 2,
      {
        align: 'right',
      },
    );
    doc.setFontSize(Primary_Font_Size);
    doc.text(
      `Invoice no: ${printData?.invoicedata?.value ?? ''}`,
      SECTION_WIDTH + (OUTER_CORDINATE_X - 3),
      OUTER_CORDINATE_Y + 6.8 + ADD_SPACE_BY * 3,
      {
        align: 'right',
      },
    );
    doc.setFontSize(Primary_Font_Size);
    printData?.paymentDetail.status &&
      doc.text(
        `Payment Mode : ${printData?.paymentDetail.mode}`,
        SECTION_WIDTH + (OUTER_CORDINATE_X - 3),
        OUTER_CORDINATE_Y + 6.8 + ADD_SPACE_BY * 4,
        { align: 'right' },
      );

    // Second Header Section
    doc.setLineWidth(0.2);
    doc.rect(
      OUTER_CORDINATE_X,
      HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y,
      SECTION_WIDTH,
      SECOND_HEADER_RECTANGLE_HEIGHT,
    );

    // doc.line(9,HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6,SECTION_WIDTH + 3,HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6 )
    printData?.dataMiscellaneousList.map((data, index) => {
      doc.setFontSize(Primary_Font_Size - 0.5);
      doc.setFont('Helvetica', '');
      doc.text(
        data.title ?? '',
        OUTER_CORDINATE_X + 3 + index * getHeaderWidth,
        HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + Primary_Font_Size - 2,
      );
      doc.setFontSize(Primary_Font_Size);
      doc.setFont('Helvetica', 'bold');
      const splitTitle = doc.splitTextToSize(data.value ?? '', getHeaderWidth - 1);
      doc.text(
        splitTitle,
        OUTER_CORDINATE_X + 3 + index * getHeaderWidth,
        HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + Primary_Font_Size - 2 + ADD_SPACE_BY,
      );
    });
  };

  const addPaginationNumber = () => {
    // @ts-ignore
    const pageStartY = PAGE_HEIGHT - OUTER_CORDINATE_Y;
    doc.setFont('Helvetica', '');
    // middle align page number
    setTitle(`Page ${doc.page++}`, 9, pageStartY);
  };

  const getFooterDetail = (data, partition = 1, increaseCoordinateYBy) => {
    const dataPartitionWidth = (SECTION_WIDTH + (OUTER_CORDINATE_X - 3)) / (!!partition ? partition : 1);
    data.map((detail, index) => {
      doc.setFontSize(Primary_Font_Size);
      doc.setFont('Helvetica', 'normal');
      doc.setTextColor('normal');

      if (index === 0) {
        doc.text(`${detail.title ?? ''}:`, OUTER_CORDINATE_X + 3, increaseCoordinateYBy, {
          align: 'left',
        });
      } else if (index === data.length - 1) {
        doc.text(
          `${detail.title ?? ''}:`,
          OUTER_CORDINATE_X - 3 + SECTION_WIDTH - doc.getTextWidth(detail.value ?? ''),
          increaseCoordinateYBy,
          { align: 'right' },
        );
      } else {
        doc.text(
          `${detail.title ?? ''}:`,
          OUTER_CORDINATE_X + 3 + index * dataPartitionWidth + dataPartitionWidth / partition,
          increaseCoordinateYBy,
          { align: 'center' },
        );
      }
      doc.setTextColor(80);
      if (index === 0) {
        doc.text(
          `${detail.value ?? ''}`,
          OUTER_CORDINATE_X + 3 + doc.getTextWidth(detail.title ?? '') + 2,
          increaseCoordinateYBy,
          { align: 'left' },
        );
      } else if (index === data.length - 1) {
        doc.text(`${detail.value ?? ''}`, OUTER_CORDINATE_X - 3 + SECTION_WIDTH + 1, increaseCoordinateYBy, {
          align: 'right',
        });
      } else {
        doc.text(
          `${detail.value ?? ''}`,
          OUTER_CORDINATE_X +
            3 +
            index * dataPartitionWidth +
            doc.getTextWidth(detail.title ?? '') +
            dataPartitionWidth / partition +
            1,
          increaseCoordinateYBy,
          { align: 'center' },
        );
      }
    });
    doc.setTextColor('normal');
  };

  const finalFooterSection = () => {
    // @ts-ignore
    // doc.lastAutoTable.finalY = PAGE_HEIGHT - 12;
    const finalFooterCoordinateY = doc.lastAutoTable.finalY - FOOTER_RECTANGLE_HEIGHT;

    doc.rect(OUTER_CORDINATE_X, finalFooterCoordinateY - 5, SECTION_WIDTH, 5);
    doc.rect(OUTER_CORDINATE_X, finalFooterCoordinateY, SECTION_WIDTH, 5);
    const footerItemsCount = configData?.footer?.length ?? 1;
    doc.rect(OUTER_CORDINATE_X, finalFooterCoordinateY - 5 * footerItemsCount, SECTION_WIDTH, 5 * footerItemsCount);

    // doc.rect(OUTER_CORDINATE_X, PAGE_HEIGHT - 10, SECTION_WIDTH, -12, 'F');
    // doc.rect(OUTER_CORDINATE_X, finalFooterCoordinateY, SECTION_WIDTH, -12, 'F');
    // doc.rect(OUTER_CORDINATE_X, finalFooterCoordinateY - FOOTER_RECTANGLE_HEIGHT, SECTION_WIDTH, -2, 'F');

    // configData?.footer

    // doc.setFont('Helvetica', 'bold');
    // doc.setFontSize(Primary_Font_Size);
    // const footerTotalData = configData?.footer?.length > 0 ? [...configData.footer].reverse() : [];
    // footerTotalData.map((item, index) => {
    //   doc.text(
    //     item[0].content + ': ' + item[1].content,
    //     SECTION_WIDTH + (OUTER_CORDINATE_X - 3),
    //     finalFooterCoordinateY + 3.8 - 4.9 * (index + 1),
    //     { align: 'right', minWidth: '180' },
    //   );
    // });

    doc.setFontSize(Secondary_Font_Size);
    doc.setFont('Helvetica', 'normal');
    doc.text(
      `Invoiced By ${printData?.tableFooterData?.invoiceDetail?.value}`,
      OUTER_CORDINATE_X + 3,
      finalFooterCoordinateY - 1.3,
    );

    const totalInWords = printData?.tableFooterData?.totalInWords ?? '';
    const totalInWordsSize = doc.setFontSize(Secondary_Font_Size);
    doc.setFont('Helvetica', 'normal');
    doc.text(
      'In Words: ',
      SECTION_WIDTH +
        (OUTER_CORDINATE_X - 3) -
        totalInWordsSize.getTextWidth(totalInWords) -
        totalInWordsSize.getTextWidth('In Words: '),
      finalFooterCoordinateY + 3.5,
    );
    doc.setFontSize(Secondary_Font_Size);
    doc.setFont('Helvetica', 'italic');
    doc.text(totalInWords, SECTION_WIDTH + (OUTER_CORDINATE_X - 3), finalFooterCoordinateY + 3.5, { align: 'right' });

    // printData?.tableFooterData?.printInfo && getFooterDetail(printData?.tableFooterData?.printInfo ?? [], 4, finalFooterCoordinateY + 10);

    // getFooterDetail( printData?.agentInfo, 2, finalFooterCoordinateY + 15);

    printData?.footerMiscellaneousList?.map((item, index) => {
      incrementFooterMiscellaneousBy = (index + 2) * increaseSpaceByFontSize(5);
      getFooterDetail(item?.data ?? [], item.partition ?? 0, finalFooterCoordinateY + incrementFooterMiscellaneousBy);
    });

    doc.setFontSize(Primary_Font_Size);
    doc.setFont('Helvetica', 'normal');
    doc.text(
      'Remarks: ',
      OUTER_CORDINATE_X + 3,
      finalFooterCoordinateY + increaseSpaceByFontSize(5) + incrementFooterMiscellaneousBy,
    );
    doc.setTextColor('normal');
    doc.setFont('Helvetica', 'bold');
    const text = printData?.tableFooterData?.remarks ?? '';
    const limitedRemarkText = turnicateString(text, Math.floor((216 * 6) / Primary_Font_Size));
    const remark = doc.splitTextToSize(
      limitedRemarkText,
      PAGE_WIDTH - 60 - (doc.getTextWidth('Remarks: ') + increaseSpaceByFontSize(8)),
    );
    doc.text(
      remark,
      OUTER_CORDINATE_X + 3 + doc.getTextWidth('Remarks: '),
      finalFooterCoordinateY + increaseSpaceByFontSize(5) + incrementFooterMiscellaneousBy,
    );

    // signature section
    doc.line(
      OUTER_CORDINATE_X + 3 + 2,
      finalFooterCoordinateY + increaseSpaceByFontSize(16) + incrementFooterMiscellaneousBy,
      OUTER_CORDINATE_X + 3 + increaseSpaceByFontSize(30),
      finalFooterCoordinateY + increaseSpaceByFontSize(16) + incrementFooterMiscellaneousBy,
    );
    doc.setFontSize(Primary_Font_Size);
    doc.setFont('Helvetica', 'normal');
    doc.text(
      `Receiver Signature`,
      OUTER_CORDINATE_X + 3 + 8,
      finalFooterCoordinateY + increaseSpaceByFontSize(20) + incrementFooterMiscellaneousBy,
      {
        align: 'left',
      },
    );
    doc.line(
      SECTION_WIDTH + 3,
      finalFooterCoordinateY + increaseSpaceByFontSize(16) + incrementFooterMiscellaneousBy,
      SECTION_WIDTH + 3 - increaseSpaceByFontSize(30),
      finalFooterCoordinateY + increaseSpaceByFontSize(16) + incrementFooterMiscellaneousBy,
    );
    doc.setFontSize(Primary_Font_Size);
    doc.setFont('Helvetica', 'normal');
    doc.text(
      `Authorized Signature`,
      SECTION_WIDTH + (OUTER_CORDINATE_X - 10),
      finalFooterCoordinateY + increaseSpaceByFontSize(20) + incrementFooterMiscellaneousBy,
      {
        align: 'right',
      },
    );

    // footermark
    doc.setTextColor('normal');
    doc.setFont('Helvetica', 'bold');
    const remarkText = printData?.tableFooterData?.printFooterNote ?? '';
    const printRemarkText = turnicateString(remarkText, Math.floor((256 * 6) / Primary_Font_Size));
    const printRemark = doc.splitTextToSize(printRemarkText ? `"${printRemarkText}"` : '', PAGE_WIDTH - 30);
    printRemark &&
      doc.text(
        printRemark,
        OUTER_CORDINATE_X + 3,
        finalFooterCoordinateY + increaseSpaceByFontSize(26) + incrementFooterMiscellaneousBy,
      );
  };

  let getFooterCoordinate;
  let maxPage;

  const pdfContent = (headerTitle, deletePage = false, spacing) => {
    if (deletePage) {
      getFooterCoordinate = doc.lastAutoTable.finalY;
    }
    // Table section
    autoTable(doc, {
      theme: 'grid',
      bodyStyles: { lineColor: BORDER_COLOR },
      tableWidth: PAGE_WIDTH - Decrease_Width_BY,
      margin: { left: OUTER_CORDINATE_X, right: OUTER_CORDINATE_X, bottom: 11 },
      showFoot: 'lastPage',
      styles: {
        minCellHeight: 9,
        valign: 'middle',
        textColor: 'black',
        fontSize: Primary_Font_Size,
        cellPadding: 1.2,
      },
      // pageBreakAfter: 'auto',
      rowPageBreak: 'avoid',
      startY: HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + SECOND_HEADER_RECTANGLE_HEIGHT,
      headStyles: {
        lineWidth: 0.1,
        lineColor: BORDER_COLOR,
        fillColor: 'white',
        textColor: 'black',
        fontSize: Secondary_Font_Size,
        minCellHeight: 7,
      },
      footStyles: {
        textColor: 'black',
        fontSize: Secondary_Font_Size,
        fillColor: false,
        minCellHeight: 0,
        cellPadding: { bottom: 0.9, top: 0.9, right: 2 },
      },
      tableLineWidth: 0.2,
      tableLineColor: BORDER_COLOR,

      // @ts-ignore
      columnStyles: configData?.columnStyles ?? {},
      head: configData?.columns ?? [],
      // @ts-ignore
      body: printData?.tableDataRows,
      // @ts-ignore
      foot: configData?.footer ?? '',
      // foot: [[]],

      // using didParseCell for adding style to body cell and footer cell
      didParseCell(data) {
        // this conditional body is for multiple footer print for making
        // total section bold
        if (variation === 'multipleFooter' && data.section === 'body' && data.row.raw[0] === '') {
          for (const cell in data.row.cells) {
            data.row.cells[cell].styles.fontStyle = 'bold';
          }
        }
        const headerTargetCells = configData.cellsToAlignRight;
        if (headerTargetCells[data.column.dataKey] && (data.section == 'foot' || data.section == 'body')) {
          data.cell.styles.halign = 'right';
        }

        if (data.section === 'foot') {
          data.cell.styles.lineColor = BORDER_COLOR;
          if (data.row.index === 0 && doc.lastAutoTable.finalY) {
            data.cell.styles.minCellHeight = PAGE_HEIGHT - 10 - spacing ?? doc.lastAutoTable.finalY;
            data.cell.styles.valign = 'bottom';
          }
          if (data.row.index === configData?.footer.length - 1) {
            data.cell.styles.minCellHeight = FOOTER_RECTANGLE_HEIGHT + 4.5;
            data.cell.styles.valign = 'top';
          }
        }
      },
      // using didDrawPage to add topheader section to every page
      didDrawPage(data) {
        maxPage = data.table.pageCount;
        // data.settings.margin.top adds top space to every page after first page
        data.settings.margin.top = HEADER_RECTANGLE_HEIGHT + SECOND_HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y;
        data.settings.margin.bottom = 11;
        addTopHeaderSection(headerTitle);

        // Adding pagination to every page
        doc.setTextColor(50);
        // @ts-ignore
        addPaginationNumber();
      },
    });
    // Final footer section
    finalFooterSection();
    if (deletePage) {
      for (let i = 1; i <= maxPage; i++) {
        doc.deletePage(i);
      }
      for (let i = 1; i <= maxPage; i++) {
        doc.deletePage(i);
      }
    }

    // if (dontRenderInView) {
    //   doc.addPage();
    // }
    // !(printBillCounter - 1 === indexCount) && doc.addPage();
    doc.addPage();
  };

  const printBillCounter = printData?.printBillCounter ?? 0;
  pdfContent('headerTitle - 1 ok', true, getFooterCoordinate);
  pdfContent('headerTitle - 2 ok', true, getFooterCoordinate);

  if (!!printBillCounter) {
    for (let count = 0; count < printBillCounter; count++) {
      const headerTitle = printData?.printCountExist
        ? printData?.title?.COPY
        : count === 0
        ? printData?.title?.ORIGINAL
        : printData?.title?.ORIGINAL2;
      // pdfContent(printBillCounter, 0, 'headerTitle - 1 ok', true, getFooterCoordinate, true);
      // pdfContent(printBillCounter, 0, 'headerTitle - 2 ok', true, getFooterCoordinate, true);
      doc.page = 1;
      pdfContent(headerTitle, false, getFooterCoordinate);
    }
  } else {
    const headerTitle = printData?.title?.original;
    // pdfContent(printBillCounter, 0, 'headerTitle - 1 ok', true, getFooterCoordinate, false);
    // pdfContent(printBillCounter, 0, 'headerTitle - 2 ok', true, getFooterCoordinate, false);
    doc.page = 1;
    pdfContent(headerTitle, false, getFooterCoordinate);
  }

  // adds pdf content to printer tab
  doc.autoPrint();
  // dataurlnewwindow
  // const uri = doc.output(openOnNewTab ? 'dataurlnewwindow' : 'datauristring');

  const pageCount = doc.internal.getNumberOfPages();
  doc.deletePage(pageCount);

  const uri = doc.output('bloburl');
  // doc.save('testing.pdf')
  return uri && uri;
  // window.open(doc.output("bloburl"), "_blank");
};

const useComplexPrintPdfContent = (configData, printData, orientation, variation, openOnNewTab) => {
  const uri = printLedgerDetailPdfContent(configData, printData, orientation, variation, openOnNewTab);
  return uri;
};

export default useComplexPrintPdfContent;
