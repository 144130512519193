import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import useLedgerData from './useLedgerData';

const printLedgerPdfContent = (ledgerData) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
  const SECTION_WIDTH = PAGE_WIDTH - 12;
  const HEADER_RECTANGLE_HEIGHT = 24;
  const LEDGER_REPORT_RECTANGLE_HEIGHT = 13.5;
  const FOOTER_RECTANGLE_HEIGHT = 22;
  const BORDER_COLOR = '#272833';
  const OUTER_CORDINATE_X = 6;
  const OUTER_CORDINATE_Y = 6;

  const columns = [
    [
      { content: 'SN', rowSpan: 2 },
      { content: 'Ledger Name', rowSpan: 2 },
      // { content: 'Address', rowSpan: 2 },
      { content: 'PAN', rowSpan: 2 },
      { content: 'Opening Balance', rowSpan: 2 },
      { content: 'Period', colSpan: 2, styles: { halign: 'center' } },
      { content: 'Closing Balance', rowSpan: 2 },
    ],
    [
      { content: 'Debit', styles: { halign: 'right', valign: 'middle' } },
      { content: 'Credit', styles: { halign: 'right', valign: 'middle' } },
    ],
  ];
  const rows = ledgerData.tableDataRows;
  console.log('ledgerData',ledgerData)
  const footer = [
    [
      '',
      'Total',
      '',
      { content: ledgerData.tableDataTotalOpening, styles: { lineWidth: 0.1, lineColor: BORDER_COLOR } },
      { content: ledgerData.tableDataTotalDebit, styles: { lineWidth: 0.1, lineColor: BORDER_COLOR } },
      { content: ledgerData.tableDataTotalCredit, styles: { lineWidth: 0.1, lineColor: BORDER_COLOR } },
      { content: ledgerData.tableDataTotalClosing, styles: { lineWidth: 0.1, lineColor: BORDER_COLOR } },
    ],
  ];

  // global font setting
  doc.setFont('Helvetica', '');

  const setTitleCenter = (title, alignTitleCenter, startTopCordinate) => {
    doc.text(title, alignTitleCenter, startTopCordinate, { align: 'left' });
  };

  const setTitle = (titleName, titleSize, startTopCordinate, leftSpace = false, concatText = '') => {
    const title = titleName;
    const fontSize = doc.setFontSize(titleSize);
    const middleCordinate = PAGE_WIDTH / 2;
    const alignTextCenter = (fontSize.getTextWidth(title) + fontSize.getTextWidth(concatText)) / 2;
    const alignTitleCenter = middleCordinate - alignTextCenter + (leftSpace ? fontSize.getTextWidth(concatText) : 0);
    setTitleCenter(title, alignTitleCenter, startTopCordinate);
  };

  const addTopHeaderSection = () => {
    // top Header
    doc.setLineWidth(0.2);
    doc.setDrawColor(BORDER_COLOR);
    doc.rect(OUTER_CORDINATE_X, OUTER_CORDINATE_Y, SECTION_WIDTH, HEADER_RECTANGLE_HEIGHT);

    // left align section
    ledgerData.companyLogo && doc.addImage(ledgerData.companyLogo, '', 10, 8, 35, HEADER_RECTANGLE_HEIGHT - 4);

    // middle align section
    doc.setFont('Helvetica', 'bold');
    setTitle(`${ledgerData.companyName}`, 12, 13);
    // to remove bold style
    doc.setFont('Helvetica', '');

    setTitle(`Address: ${ledgerData.address}`, 7, 17);
    setTitle(`Phone: ${ledgerData.phone}`, 7, 20);
    setTitle(`PAN No: ${ledgerData.panNo}`, 7, 23);
    setTitle(`Email: ${ledgerData.email}`, 7, 26);

    // right align section
    // doc.setFontSize(7);
    // doc.text(`Accounting Period`, SECTION_WIDTH + 2, 17, { align: 'right' });
    // doc.setFontSize(7);
    // doc.setFont('Helvetica', 'bold');
    // doc.text(`21 Jul, 2021 - 29 Jul, 2021`, SECTION_WIDTH + 2, 20, { align: 'right' });

    // ledger report section
    doc.setLineWidth(0.2);
    doc.rect(
      OUTER_CORDINATE_X,
      HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y,
      SECTION_WIDTH,
      LEDGER_REPORT_RECTANGLE_HEIGHT,
    );

    doc.setFont('Helvetica', 'bold');
    setTitle(`Ledger Report`, 12, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 6);
    doc.setFont('Helvetica', '');

    const dateText = `${ledgerData.reportPeriod?.start ?? ''} - ${ledgerData.reportPeriod?.end ?? ''}`;
    setTitle(`Report Period: `, 7, HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10, 0, dateText);

    const dateTitleText = 'Report Period: ';
    doc.setFont('Helvetica', 'bold');
    setTitle(
      `${ledgerData.reportPeriod?.start ?? ''} - ${ledgerData.reportPeriod?.end ?? ''}`,
      7,
      HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y + 10,
      true,
      dateTitleText,
    );
  };

  const addPaginationNumber = pageNUmber => {
    // @ts-ignore
    const pageStartY = PAGE_HEIGHT;
    doc.setFont('Helvetica', '');
    // middle align page number
    setTitle(`Page ${pageNUmber.toString()}`, 9, pageStartY - OUTER_CORDINATE_Y);
  };

  // Table section
  autoTable(doc, {
    theme: 'grid',
    bodyStyles: { lineColor: BORDER_COLOR },
    tableWidth: PAGE_WIDTH - 12,
    margin: { left: 6, right: 6, bottom: 11 },
    showFoot: 'lastPage',
    styles: {
      minCellHeight: 9,
      valign: 'middle',
      fontSize: 8,
      textColor: 'black',
    },
    rowPageBreak: 'avoid',
    startY: HEADER_RECTANGLE_HEIGHT + LEDGER_REPORT_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y,
    headStyles: {
      lineWidth: 0.1,
      lineColor: BORDER_COLOR,
      fillColor: 'white',
      textColor: 'black',
      fontSize: 8.5,
      minCellHeight: 7,
    },
    footStyles: { textColor: 'black', fillColor: false },
    tableLineWidth: 0.2,
    tableLineColor: BORDER_COLOR,

    // @ts-ignore
    columnStyles: {
      0: { cellWidth: 10 },
      2: { cellWidth: 26 },
      3: { cellWidth: 18 },
    },
    head: columns,
    // @ts-ignore
    body: rows,
    // @ts-ignore
    foot: footer,

    // using didParseCell for adding style to body cell and footer cell
    didParseCell(data) {
      const headerTargetCells = { 4: '4', 5: '5', 6: '6', 7: '7' };
      if (headerTargetCells[data.column.dataKey] && (data.section == 'foot' || data.section == 'body')) {
        data.cell.styles.halign = 'right';
      }
    },
    // using didDrawPage to add topheader section to every page
    didDrawPage(data) {
      // data.settings.margin.top adds top space to every page after first page
      data.settings.margin.top = HEADER_RECTANGLE_HEIGHT + LEDGER_REPORT_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y;
      data.settings.margin.bottom = 11;
      addTopHeaderSection();
      const pageCount = doc.internal;

      // Adding pagination to every page
      doc.setTextColor(50);

      // @ts-ignore
      addPaginationNumber(pageCount.getNumberOfPages());
    },
  });

  // Final footer section
  // finalFooterSection();

  // adds pdf content to printer tab
   doc.autoPrint();

  const uri = doc.output('dataurlstring');
  return uri && uri;
  // window.open(doc.output("bloburl"), "_blank");
};

const useLedgerPdfContent = (ledgerList, companyData, ledgerDate) => {
  const ledgerData = useLedgerData(ledgerList, companyData, ledgerDate);
  const uri = printLedgerPdfContent(ledgerData);
  return uri;
};

export default useLedgerPdfContent;
