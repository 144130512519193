import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { has } from '../../../../utils/hasOwnProperty';
import { clone } from '../../../../utils/arrayProcessor';
import { SALES_RETURN_BASE } from '../../../../data/enums/Route';
import Header from '../../../../components/PrintComponent/Header';
import { checkIfApiCallSuccess, srnApi } from '../../../common/base.api';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import PageNumber from '../../../../components/PrintComponent/PageNumber';
import BillTotalContent from '../../../../components/PrintComponent/BillTotalContent';
import { Button, Cell, Grid, LinearProgress, Textfield } from '../../../../components/BillingMDC';
import config from './config';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage, voucherDecoder } from '../../../../utils/miscellaneous';
import { CLIENT, VAT_PERCENTAGE, GET_BATCH_TH } from '../../../../data/enums/enums';
import numberToWords from '../../../../utils/numberToTextConverter';
import ReportView from '../../../../components/PrintComponent/ReportView';
import { isObjectEmpty } from '../../../../utils/objectProcessor';
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import { groupPrintDataSet } from '../../../common/print.service';
import getPrintData from './getPrintData';
import getConfigData from './getConfigData';
import ComplexPrint from '../../../../components/PrintComponent/complexPrint/complexPrint';

// invoice id is present in the list.
class SalesDetails extends Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      client: CLIENT.DBS,
      loading: false,
      customerDetails: config.customerDetailMapper({}),
      billSummary: config.billSummaryMapper({}),
      date: '',
      miti: '',
      documentDate: '',
      invoiceList: [],
      printButtonClicked: false,
      billTotalList: [
        { title: 'Sub Total', value: 0 },
        { title: 'Bill Discount', value: 0 },
        {
          title: 'Trade Discount',
          value: 0,
        },
        { title: 'VAT', value: 0 },
      ],
      dataMiscellaneousList: [
        { title: 'Invoice Number', value: '' },
        {
          title: 'Sales Return Number',
          value: '',
        },
        { title: 'PAN No', value: '' },
        { title: 'Customer Name', value: '' },
      ],
      dataList: [],
      invoiceDetail: { title: 'Prepared By', value: '' },
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      paymentDetail: {
        status: true,
        mode: 'CREDIT',
      },
      totalQuantity: 0,
    };
    this.dataListLength = 0;
    this.pageConfig = this.getPageConfig();
  }
  getDetail = () => {
    const { invoiceDetail, client, paymentDetail } = this.state;
    const { match, company, location } = this.props;
    const srnNumber = voucherDecoder(match.params.id);
    const userId = match.params.userId || null;
    this.setState({ loading: true, client: userId ? CLIENT.ROSIA : CLIENT.DBS });
    srnApi
      .getPrintDetails(srnNumber)
      .then(response => {
        this.setState({ loading: false });
        if (checkIfApiCallSuccess(response)) {
          const salesReturnDetail = { ...response.data };
          let companyInfo = { ...company, printInfo: {count: company?.printInfo?.count,batchEnabled:company?.printInfo?.batchEnabled } };
          if (salesReturnDetail.paymentMode === 'CASH') {
            paymentDetail.status = true;
            paymentDetail.mode = 'CASH';
          }
          salesReturnDetail.subTotal = salesReturnDetail.details.length
            ? this.calculateTotal(salesReturnDetail.details, 'netAmount')
            : 0;
          salesReturnDetail.taxableAmount = Number(
            (
              salesReturnDetail.subTotal -
              (salesReturnDetail.billDiscount || 0) -
              (salesReturnDetail.tradeDiscount || 0) -
              (salesReturnDetail.taxExemptedAmount || 0) +
              (salesReturnDetail.excise || 0)
            ).toFixed(2),
          );
          salesReturnDetail.totalInWords = numberToWords(salesReturnDetail.netAmount || 0);
          const totalQuantity = salesReturnDetail.details.reduce((acc, curr) => {
            return acc + curr.quantity;
          }, 0);
          const customerDetails = config.customerDetailMapper(salesReturnDetail);
          const billPercent = getPercentage(salesReturnDetail.billDiscount, salesReturnDetail.subTotal);
          const tradePercent = getPercentage(
            salesReturnDetail.tradeDiscount,
            salesReturnDetail.subTotal - salesReturnDetail.billDiscount || 0,
          );

          const billSummary = config.billSummaryMapper({ ...salesReturnDetail, billPercent, tradePercent });

          const dataMiscellaneousList = [
            {
              title: 'Invoice Number',
              value: customerDetails.invoiceId,
            },
            { title: 'Sales Return Invoice Number', value: salesReturnDetail.salesReturnInvoiceNumber },
            { title: 'Customer Name', value: customerDetails.customerName },
            {
              title: 'PAN No',
              value: salesReturnDetail.customer.panNo,
            },
          ];
          const billTotalList = [
            { title: 'Sub Total', value: salesReturnDetail.subTotal },
            {
              title: `Bill Discount${billPercent ? ` (${billPercent}%)` : ''}`,
              value: billSummary.billDiscount,
            },
            {
              title: `Trade Discount${tradePercent ? ` (${tradePercent}%)` : ''}`,
              value: billSummary.tradeDiscount,
            },
            // JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise
            //   ? {
            //       title: `Excise Duty`,
            //       value: billSummary.excise,
            //     }
            //   : {},
            { title: 'Taxable Amount', value: salesReturnDetail.taxableAmount },
            { title: `VAT (${VAT_PERCENTAGE}%)`, value: billSummary.vat },
          ];
          if (JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise) {
            billTotalList.splice(3, 0, { title: 'Excise Duty', value: billSummary.excise });
          }
          if (has.call(salesReturnDetail.user || {}, 'name')) {
            invoiceDetail.value = salesReturnDetail.user.name;
          }
          if (client === CLIENT.ROSIA) {
            companyInfo = response.data.company || company;
          }
          this.setState(
            {
              totalQuantity,
              date: moment(salesReturnDetail.date).format('DD MMM YYYY'),
              miti: salesReturnDetail.miti,
              documentDate: salesReturnDetail.documentDate,
              invoiceList: salesReturnDetail.details || [],
              billSummary,
              invoiceDetail,
              billTotalList,
              customerDetails,
              dataMiscellaneousList,
              company: companyInfo,
              paymentDetail,
            },
            () => {
              if ((location && location.state && location.state.printEnable) || this.state.client === CLIENT.ROSIA) {
                this.handlePrintClick();
              }
            },
          );
        } else {
        }
      })
      .catch(err => this.setState({ loading: false }));
  };

  calculateTotal = (detailList, key) => {
    let totalValue = 0;
    detailList.map(item => {
      totalValue += item[key];
    });

    return Number(Number(totalValue).toFixed(2));
  };

  setupConfig() {
    const { match, location } = this.props;
    const invoiceHash = match.params.invoiceHash || '';
    if (!location.state && !!invoiceHash.length) {
      document.getElementsByClassName('content-drawer')[0].classList.add('visibility-hidden');
      document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    }
    // const { client } = this.state;
    // if (client === CLIENT.ROSIA) {
    //   document.getElementsByClassName('content-drawer')[0].classList.add('visibility-hidden');
    //   document.getElementsByClassName('nav-bar')[0].classList.add('visibility-hidden');
    // }
  }

  handlePrintClick = () => {
    // const { client } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true });

    // self.setState({ printButtonClicked: true }, () => {
    //   setTimeout(() => {
    //     window.print();
    //   }, 500);
    // });
    // window.onafterprint = function () {
    //   self.setState({ printButtonClicked: false }, () => {
    //     if (client === CLIENT.ROSIA) window.close();
    //   });
    // };
  };
  groupPrintDataSetNew() {
    const { invoiceList, company } = this.state;
    const printInfoBill = company.printInfo || {};
    const orders = clone(invoiceList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  handleCancelClick = () => {
    history.push(`/${SALES_RETURN_BASE}`);
  };

  // getPageConfig = () => {
  //   const {
  //     company: {
  //       printInfo: { batchEnabled },
  //     },
  //   } = this.state;
  //   const header = [...config.header];
  //   if (true) {
  //     header.splice(3, 0, GET_BATCH_TH);
  //     return { ...config, header };
  //   }
  //   return config;
  // };

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    const header = [...config.header];
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return config;
  };

  componentDidMount() {
    this.getDetail();
    this.setupConfig();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOutsideClick = event => {
    const { client } = this.state;
    if (this.box && !this.box.current.contains(event.target)) {
      this.state.printButtonClicked === true &&
        this.setState({ printButtonClicked: false }, () => {
          if (client === CLIENT.ROSIA) window.close();
        });
    }
  };

  render() {
    const {
      loading,
      invoiceList,
      dataList,
      billSummary,
      customerDetails,
      billTotalList,
      invoiceDetail,
      date,
      miti,
      printButtonClicked,
      dataMiscellaneousList,
      documentDate,
      company,
      paymentDetail,
      totalQuantity,
    } = this.state;

    const printInfoBill = company.printInfo;

    const headerData = {
      // printCountInfo: 1,
      title: config.title,
      date,
      miti,
      paymentDetail,
      dataMiscellaneousList,
    };

    const addToBillTotal = [...billTotalList, { title: 'Total', value: billSummary?.totalValue ?? 0 }];

    const totalDatas = {
      billTotalList: addToBillTotal,
      paymentMode: billSummary.paymentMode,
      invoiceDetail,
      print,
      batchEnabled: company.printInfo.batchEnabled,
      // printInfo,
      totalInWords: billSummary.totalInWords,
      // remarksStatus,
      remarks: billSummary.remarks,
      // agentInfo,
      totalQuantity,
      // printFooterNote,
    };

    console.log('company?.printInfo?.batchEnabled',company?.printInfo?.batchEnabled)

    const printData = getPrintData([...invoiceList], { ...company }, headerData, totalDatas);
    const configData = getConfigData(printData?.tableFooterData, company?.printInfo?.batchEnabled);
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div style={{ display: `${this.state.client === CLIENT.ROSIA ? 'none' : ''}` }}>
          <div className={`srn srn-detail sales-invoice ${loading ? 'clickable-false' : ''}`}>
            <div>
              <div className="card-body" ref="tableReference">
                <div className="header-bar">
                  <Grid>
                    <Cell col={4} tablet={8}>
                      <div className="header-title">{company.title}</div>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Address: </span>
                        <span>{company.address}</span>
                      </span>
                      <span className="header-title-content">
                        <span className="active-opacity-text">Phone: </span>
                        <span>{company.phone}</span>
                      </span>
                      <span className="no-margin-bottom header-title-content">
                        <span className="active-opacity-text">PAN No: </span>
                        <span>{company.panNumber}</span>
                      </span>
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <div className="header-middle-title center-align">
                        <h2>{config.title.original}</h2>
                      </div>
                    </Cell>
                    <Cell col={4} tablet={8}>
                      <div className="right-align">
                        <span className="header-right-content">
                          <span className="">Date: </span>
                          <span>{moment(documentDate).format('ll')}</span>
                        </span>
                        <span className="">Payment Mode: </span>
                        <span>{paymentDetail.mode}</span>
                      </div>
                    </Cell>
                  </Grid>
                </div>
                <div className="print-invoice three-input-wrapper">
                  <Grid>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="Sales Return Number"
                        value={customerDetails?.salesReturnNumber}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="Invoice Number"
                        value={customerDetails?.invoiceId}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="Customer Name"
                        value={customerDetails?.customerName}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="Address"
                        value={customerDetails?.customerAddress}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="PAN No"
                        value={customerDetails?.panNo}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="Remarks"
                        value={billSummary.remarks}
                        onChange={() => null}
                      />
                    </Cell>
                    <Cell col={3}>
                      <Textfield
                        className="right-side-gap no-pointer-events"
                        floatingLabel="SalesPerson"
                        value={billSummary.agentName}
                        onChange={() => null}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div ref="fixedTableBody" className="fixed-table-wrapper">
                  <table>
                    <TableHeader headerDetails={this.pageConfig.header} filterHeaderLabel={false} />
                    {config.getTableBody({ dataList: invoiceList, printInfoBill })}
                  </table>
                </div>
                <div className="total-section-wrapper">
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Sub Total
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(billSummary.subTotal)}
                    </Cell>
                  </Grid>
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Bill Discount {billSummary.billPercent ? `(${billSummary.billPercent}%)` : ''}
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(billSummary.billDiscount)}
                    </Cell>
                  </Grid>
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Trade Discount {billSummary.tradePercent ? `(${billSummary.tradePercent}%)` : ''}
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(billSummary.tradeDiscount)}
                    </Cell>
                  </Grid>
                  {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        Excise Duty
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {fixedFloatAndCommas(billSummary.excise)}
                      </Cell>
                    </Grid>
                  )}
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Taxable Amount
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(billSummary.taxableAmount)}
                    </Cell>
                  </Grid>
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      {' '}
                      VAT
                    </Cell>
                    <Cell col={1} className="right-align">
                      {' '}
                      {fixedFloatAndCommas(billSummary.vat)}
                    </Cell>
                  </Grid>
                  <div className="hr" />
                  <Grid>
                    <Cell col={8} />
                    <Cell col={3} className="right-align active-opacity-text">
                      Total
                    </Cell>
                    <Cell col={1} className="right-align font-bold">
                      {' '}
                      {fixedFloatAndCommas(billSummary.totalValue)}
                    </Cell>
                  </Grid>
                </div>
              </div>
              <div className="newinvoice-btn-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={4} className="right-align">
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        accent
                        className="cancel-btn modal-btn"
                        onClick={() => {
                          this.handleCancelClick();
                        }}
                      >
                        Cancel
                      </Button>
                      <div ref={this.box}>
                        <Button
                          accent
                          className="save-btn modal-btn"
                          onClick={() => {
                            this.handlePrintClick();
                          }}
                        >
                          Print
                        </Button>
                      </div>
                    </div>
                  </Cell>
                </Grid>
              </div>
            </div>
            {printButtonClicked && (
              <div className={'display-block portrait'}>
                {/* <ReportView
              billTotalList={billTotalList}
              billSummary={billSummary}
              dataList={dataList}
              dataMiscellaneousList={dataMiscellaneousList}
              date={moment(documentDate).format('ll')}
              company={company}
              miti={miti}
              title={config.title}
              invoiceDetail={invoiceDetail}
              paymentDetail={paymentDetail}
              invoiceList={invoiceList}
              config={config}
              pageConfig={this.pageConfig}
              batchControl
              totalQuantity={totalQuantity}
            /> */}
                <ComplexPrint configData={configData} printData={printData} orientation={'portrait'} />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: `${this.state.client === CLIENT.ROSIA ? 'flex' : 'none'}`,
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 74px)',
            width: '100%',
          }}
        >
          {/* the translate part is width of drawer minus content-body left padding, height of nav minus content-body top padding */}
          <p style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)' , transform: 'translate(-44px,-54px)'}}>
            Click anywhere to close this tab
          </p>
        </div>
      </InvoiceDetailsStyled>
    );
  }
}

SalesDetails.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});
const SRNDetails = connect(mapStateToProps)(SalesDetails);

export default SRNDetails;
