import React from 'react';
import { formatToReadableDate } from '../../../../utils/filterUtil';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { Icon } from '../../../../components/BillingMDC';
import { ACTION } from '../../../../data/enums/enums';
import BillingTextField from '../../../../components/TextField/BillingTextField';

const StockLedgerDetailBody = ({ dataList,handleInputChange,onRowClick,dialogType ,activeIndex}) => (
  <tbody>
    {dataList.map((data,index) => (
      <tr>
        <td className='left-align'>{data?.date ? formatToReadableDate(data?.date) : null}</td>
        <td className='left-align'>{data?.vouchernumber || ''}</td>
        <td className='left-align'>{data?.customer_title || ''}</td>
        <td className='left-align'>{data?.type || ''}</td>
        <td className='left-align'>{data?.batch || ''}</td>
        <td className='right-align'>{data?.quantity || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.dlp || 0)}</td>
        <td className={`text-right costPer ${(dialogType ===ACTION.UPDATE && activeIndex === data?.id) ?  'enabled' :'disabled'}`}>
          <BillingTextField
            value={ data?.per_unit_rate}
            handleChange={(e, value) => handleInputChange(value,'perUnitRate',data,index)}
            param='perUnitRate'
            type='number'
            className='right-side-gap'
            zeroError={false}
        />
      </td>
       <td className='right-align'>
         {data?.isEditableRate && 
      <Icon name='edit' onClick={() => onRowClick(ACTION.UPDATE,data)} />
         }
    </td>
      </tr>
    ))}
  </tbody>
);

const stockLedgerReportDetailConfig = [
  {
    id: 2,
    label: 'date',
    title: 'Date',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 3,
    label: 'vouchernumber',
    title: 'Voucher Number',
    className: 'left-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'customer_title',
    title: 'Customer',
    className: 'left-align',
    sortable: false,
  },
  {
    id: 3,
    label: 'type',
    title: 'Transaction type',
    className: 'left-align',
    sortable: false,
  },
  { id: 4, label: 'batch', title: 'Batch', className: 'left-align', sortable: false },
  {
    id: 5,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  { id: 6, label: 'dlp', title: 'DLP', className: 'right-align', sortable: false },
  {
    id: 7,
    label: 'per_unit_rate',
    title: 'Per Unit Rate',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: '',
    title: '',
    className: 'right-align',
    sortable: false,
  },
];

export const getPayloadUpdate= (data) => {
	return({
	id:data?.title || data?.id ||'',
	sku_id:data?.sku_id || null,
	business_id:data?.business_id,
	batch_id:data?.batch_id || null,
	per_unit_rate:data?.per_unit_rate || null,
})};

const config = {
  header: stockLedgerReportDetailConfig,
  getTableBody: StockLedgerDetailBody,
  getPayloadUpdate,
};

export default config;
