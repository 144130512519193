import React from '../../../../node_modules/react';
import PropTypes from '../../../../node_modules/prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const PurchaseVatReportTableBody = ({
  dataSet = [],
  remainingLines,
  columnTotal = {},
  footer = false,
  onTableBodyClick,
}) => {
  return (
    <>
      {dataSet.map(data => {
        const totalPurchase = (data.tax_exempted_amount ?? 0) + (data.taxable_purchase_amount ?? 0);
        return (
          <tr className="cursor-pointer">
            <td className="left-align miti">{data.miti_title}</td>
            <td className="left-align bill-no link-voucher" onClick={() => onTableBodyClick(data)}>
              {data.bill_no}
            </td>
            <td className="left-align bill-no">{data.invoice_number} </td>
            <td className="left-align ledger-name">{data.ledger_name}</td>
            <td className="left-align pan-no">{data.pan_no}</td>
            <td className="left-align sku">{data.sku}</td>
            <td className="right-align quantity">{data.quantity}</td>
            <td className="right-align unit">{data.quantity > 1 ? 'PCs' : 'PC'}</td>
            <td className="right-align total-purchase">{fixedFloatAndCommas(totalPurchase) ?? '0'}</td>
            <td className="right-align tax">
              {data.tax_exempted_amount ? fixedFloatAndCommas(data.tax_exempted_amount) : data.tax_exempted_amount}
            </td>
            <td className="right-align tax-amount">
              {data.taxable_purchase_amount
                ? fixedFloatAndCommas(data.taxable_purchase_amount)
                : data.taxable_purchase_amount}
            </td>
            <td className="right-align tax-vat">
              {data.taxable_purchase_vat ? fixedFloatAndCommas(data.taxable_purchase_vat) : data.taxable_purchase_vat}
            </td>
            <td className="right-align import-amount">
              {data.import_amount ? fixedFloatAndCommas(data.import_amount) : 0}
            </td>
            <td className="right-align import-vat">{data.import_vat ? fixedFloatAndCommas(data.import_vat) : 0}</td>
            <td className="right-align capitalize">
              {data.capitalized_purchase_amount ? fixedFloatAndCommas(data.capitalized_purchase_amount) : 0}
            </td>
            <td className="right-align capitalize-vat">
              {data.capitalized_purchase_vat ? fixedFloatAndCommas(data.capitalized_purchase_vat) : 0}
            </td>
            {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
              <td className="right-align">{data.excise ? fixedFloatAndCommas(data.excise) : data.excise}</td>
            )}
          </tr>
        );
      })}
      {footer && <Total columnTotal={columnTotal} />}
      {remainingLines && remainingLines.length
        ? remainingLines.map(i => (
            <tr className="blank-tr">
              <td></td>
              <td colSpan="14" className=""></td>
            </tr>
          ))
        : null}
    </>
  );
};

PurchaseVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};
const Total = ({ columnTotal = {} }) => (
  <tr className="total">
    <td colSpan="5" />
    <td className="font-bold right-align">Total</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalQuantity)}</td>
    <td />
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.taxExemptedPurchase)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.vat)}</td>
    <td className="font-bold right-align">{fixedFloatAndCommas(columnTotal.exportPurchase)}</td>
    <td colSpan="3"></td>
    {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && (
      <td className="right-align">
        {columnTotal.excise ? fixedFloatAndCommas(columnTotal.excise) : columnTotal.excise}
      </td>
    )}
  </tr>
);
const PRVRTableFooter = ({ columnTotal = {} }) => (
  <tfoot className="right-content-footer">
    <Total columnTotal={columnTotal} />
  </tfoot>
);

const PurchaseVatReportConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer left-align miti',
      sortable: true,
    },
    {
      id: 2,
      label: 'purchase_return_bill_number',
      title: 'Purchase Return Bill Number',
      className: 'cursor-pointer left-align invoice-number',
      sortable: true,
    },
    {
      id: 3,
      label: 'purchase_bill_number',
      title: 'Purchase Bill Number',
      className: 'cursor-pointer left-align bill-no',
      sortable: true,
    },
    {
      id: 4,
      label: 'vendor_name',
      title: 'Vendor Name',
      className: 'cursor-pointer left-align ledger-name',
      sortable: true,
    },
    {
      id: 5,
      label: 'pan_no',
      title: 'PAN No.',
      className: 'cursor-pointer left-align pan-no',
      sortable: true,
    },
    {
      id: 6,
      label: 'item_name',
      title: 'Item Name',
      className: 'cursor-pointer left-align sku',
      sortable: true,
    },
    {
      id: 7,
      label: 'quantity',
      title: 'Item Quantity',
      className: 'cursor-pointer right-align quantity',
      sortable: true,
    },
    {
      id: 8,
      label: 'unit',
      title: 'Unit',
      className: 'cursor-pointer right-align quantity',
      sortable: true,
    },
    {
      id: 9,
      label: 'total_purchase',
      title: 'Total Purchase',
      className: 'cursor-pointer right-align tax',
      sortable: false,
    },
    {
      id: 11,
      label: 'tax_exempted_amount',
      title: 'Tax Exempted Amount',
      className: 'cursor-pointer right-align tax',
      sortable: false,
    },
    {
      id: 12,
      label: 'taxable_amount',
      title: 'Taxable Purchase',
      className: 'cursor-pointer right-align tax-amount',
      sortable: true,
    },
    {
      id: 13,
      label: 'purchase_vat',
      title: 'Purchase VAT',
      className: 'cursor-pointer right-align tax-vat',
      sortable: true,
    },
    {
      id: 14,
      label: 'import_amount',
      title: 'Import Amount',
      className: 'cursor-pointer right-align import-amount',
      sortable: true,
    },
    {
      id: 15,
      label: 'import_vat',
      title: 'Import VAT',
      className: 'cursor-pointer right-align import-vat',
      sortable: true,
    },
    {
      id: 16,
      label: 'capitalized_purchase_amount',
      title: 'Capitalized Purchase Amount',
      className: 'cursor-pointer right-align capitalize',
      sortable: true,
    },
    {
      id: 17,
      label: 'capitalized_purchase_vat',
      title: 'Capitalized Purchase VAT',
      className: 'cursor-pointer right-align capitalize-vat',
      sortable: true,
    },
   
  { id: 18, label: 'excise', title: 'Excise', className: 'cursor-pointer right-align', sortable: true },

  ],
};
const lengthyAttributes = ['sku', 'ledger_name'];
const printEnums = {
  withoutFooterLimit: 38,
  withFooterLimit: 37,
  sku: 31,
  ledger_name: 22,
  lengthyAttributes,
};

const config = {
  header: PurchaseVatReportConfig,
  getTableBody: PurchaseVatReportTableBody,
  getTableFooter: PRVRTableFooter,
  printEnums,
  uniqueIdGetter: i => i.id,
};
const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 1,
      label: 'miti_title',
      title: 'Total',
      className: 'cursor-pointer left-align miti total',
      sortable: true,
    },
    {
      id: 2,
      label: 'purchase_return_bill_number',
      title: '',
      className: 'cursor-pointer left-align invoice-number',
      sortable: true,
    },
    {
      id: 3,
      label: 'purchase_bill_number',
      title: '',
      className: 'cursor-pointer left-align bill-no',
      sortable: true,
    },
    {
      id: 4,
      label: 'vendor_name',
      title: '',
      className: 'cursor-pointer left-align ledger-name',
      sortable: true,
    },
    {
      id: 5,
      label: 'pan_no',
      title: '',
      className: 'cursor-pointer left-align pan-no',
      sortable: true,
    },
    {
      id: 6,
      label: 'item_name',
      title: '',
      className: 'cursor-pointer left-align sku',
      sortable: true,
    },
    {
      id: 7,
      label: 'quantity',
      title: 'Item Quantity',
      className: 'cursor-pointer right-align quantity',
      sortable: true,
      value: data?.totalQuantity || 0,
    },
    {
      id: 8,
      label: 'unit',
      title: '',
      className: 'cursor-pointer right-align quantity',
      sortable: true,
    },
    {
      id: 20,
      label: 'total_purchase',
      title: 'Total Purchase',
      className: 'cursor-pointer right-align tax',
      sortable: false,
      value: fixedFloatAndCommas(data?.grossTotalTaxableAmount) || 0,
    },
    {
      id: 11,
      label: 'tax_exempted_amount',
      title: 'Tax Exempted Amount',
      className: 'cursor-pointer right-align tax',
      sortable: false,
      value: fixedFloatAndCommas(data?.taxExemptedPurchase) || 0,
    },
    {
      id: 12,
      label: 'taxable_amount',
      title: 'Taxable Purchase',
      className: 'cursor-pointer right-align tax-amount',
      sortable: true,
      value: fixedFloatAndCommas(data?.totalTaxableAmount) || 0,
    },
    {
      id: 13,
      label: 'purchase_vat',
      title: 'Purchase VAT',
      className: 'cursor-pointer right-align tax-vat',
      sortable: true,
      value: fixedFloatAndCommas(data?.vat) || 0,
    },
    {
      id: 14,
      label: 'import_amount',
      title: '',
      className: 'cursor-pointer right-align import-amount',
      sortable: true,
    },
    {
      id: 15,
      label: 'import_vat',
      title: '',
      className: 'cursor-pointer right-align import-vat',
      sortable: true,
    },
    {
      id: 16,
      label: 'capitalized_purchase_amount',
      title: '',
      className: 'cursor-pointer right-align capitalize',
      sortable: true,
    },
    {
      id: 17,
      label: 'capitalized_purchase_vat',
      title: '',
      className: 'cursor-pointer right-align capitalize-vat',
      sortable: true,
    },
    {
      id: 11,
      label: 'total_excise',
      title: 'Excise',
      className: 'right-align',
      sortable: true,
      value: fixedFloatAndCommas(data?.excise) || 0,
    },
  ];
  if (!JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise) {
    totalDetails.pop();
  }
  return totalDetails;
};

export { PurchaseVatReportConfig, PurchaseVatReportTableBody, PRVRTableFooter, config, getTotalDetails };
