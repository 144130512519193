import {
  GRN_CONFIG,
  SRN_CONFIG,
  PRN_CONFIG,
  JV_CONFIG,
  STOCK_LEDGER_CONFIG,
  SALES_INVOICE_CONFIG,
  LEDGER_REPORT_CONFIG,
  MATERIALIZED_VIEW_CONFIG,
  DATABASE_CONFIG,
  OPENING_BALANCE_CONFIG,
  SKU_CONFIG,
  STOCK_JOURNAL_CONFIG,
  LEDGER_LIST_CONFIG,
  SALES_VAT_REPORT_CONFIG,
  NEW_SRN_VAT_REPORT_CONFIG,
  STOCK_VALUATION_REPORT_CONFIG,
  AGGREGATE_LEDGER_REPORT_CONFIG,
  DAY_BOOK_SUMMARY_CONFIG,
  AGGREGATE_REPORT_JV_DETAILS_CONFIG,
  DAY_BOOK_DETAIL_CONFIG,
  STOCK_LEDGER_DETAIL_CONFIG,
  PICK_LIST_CONFIG,
  TRIAL_BALANCE_CONFIG,
  PROFIT_LOSS_CONFIG,
  BALANCE_SHEET_CONFIG,
  LEDGER_DETAILS_REPORT_CONFIG,
  FISCAL_YEAR_MIGRATION_CONFIG,
  SAMPLE_OPENING_BALANCE_CONFIG,
  PSR_CONFIG,
  SRN_DETAILS_CONFIG,
  PRN_DETAILS_CONFIG,
  NEW_SALES_VAT_REPORT_CONFIG,
  NEW_PURCHASE_VAT_REPORT_CONFIG,
  NEW_PURCHASE_RETURN_VAT_REPORT_CONFIG,
  VAT_SUMMARY_REPORT_CONFIG,
  DAMAGE_EXPIRY_VALUATION_CONFIG,
  SALES_REPORT_CONFIG,
  GRN_REPORT_CONFIG,
  PRN_REPORT_CONFIG,
  SRN_REPORT_CONFIG,
  STOCK_LEDGER_DETAIL,
  TAG_WISE_DETAILS_REPORT_CONFIG,
  SALES_SUMMARY_CONFIG,
  JV_DETAIL_CONFIG,
} from './domain.config';

import * as apiService from './api.service';
import {
  DOMAIN, REPORT_TYPE , LEDGERS
} from '../../data/enums/enums';
import * as filterUtil from '../../utils/filterUtil';
import { DEBIT_NOTE,CREDIT_NOTE } from '../../data/enums/Route';

const salesInvoiceApi = {
  getList: ({ query, extraQuery }) => apiService.getPaginatedApiWithTransformer('sales-invoices', query, extraQuery),
  create: (body) => apiService.postApiReqWithTransformer('sales-invoices', body),
  getDetails: (id) => apiService.getApiWithTransformer(`sales-invoices/detail?id=${id}`),
  getInvoiceNumberList: ({ invoiceString, businessId }) => apiService.getApiWithTransformer(
    `sales-invoices/detail/invoiceList?invoice_number=${invoiceString}&business_id=${businessId}`
  ),
  getPrintDetails: (query) => apiService.getApiWithTransformer('sales-invoices/detail?', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(SALES_INVOICE_CONFIG.domain, type, query),
  detailDownloadList: ({ type, query }) => apiService.downloadApiWithTransformer(PSR_CONFIG.domain, type, query),
  getAgentList: (query) => apiService.getApiWithTransformer('agents/list?is_non_rosia=true', query),
};
const salesReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('archive-reports/sales', query),
  upload: (query) => apiService.multipartPostWithTransformer('archive-reports/upload-sales', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(SALES_REPORT_CONFIG.domain, type, query),
};
const srnReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('archive-reports/sales-returns', query),
  upload: (query) => apiService.multipartPostWithTransformer('archive-reports/upload-sales-returns', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(SRN_REPORT_CONFIG.domain, type, query),
};
const prnReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('archive-reports/purchase-returns', query),
  upload: (query) => apiService.multipartPostWithTransformer('archive-reports/upload-purchase-returns', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(PRN_REPORT_CONFIG.domain, type, query),
};
const grnReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('archive-reports/purchases', query),
  upload: (query) => apiService.multipartPostWithTransformer('archive-reports/upload-purchases', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(GRN_REPORT_CONFIG.domain, type, query),
};

const salesMVApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('sales-invoices/view', query),
  getEntireList: (query) => apiService.getApiWithTransformer('sales-invoices/view', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(MATERIALIZED_VIEW_CONFIG.domain, type, query),
};

const entryLogApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('entry-logs', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(MATERIALIZED_VIEW_CONFIG.domain, type, query),
};

const buApi = { getList: (query) => apiService.getApiWithTransformer('business-company', query), };

const companyApi = {
  getSettings: (query) => apiService.getApiWithTransformer('company/settings/', query),
  updateSettings: (query) => apiService.postApiWithTransformer('company/settings/', query),
  getDetails: (query) => apiService.getApiWithTransformer('company/', query),
  create: (query) => apiService.postApiWithTransformer('company/', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('company', id, query),
  getFiscalYear :(query) => apiService.getApiWithTransformer('fiscal-years/list', query),
};

const dayBookSummaryApi = {
  getDetails: (date) =>
    apiService.getApiWithTransformer(`ledgers/day-book?date=${filterUtil.formatToNumericDate(date)}`, {}),
  // eslint-disable-next-line max-len
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(DAY_BOOK_SUMMARY_CONFIG.domain, type, query),
  downloadDetail: ({ type, query }) =>
    apiService.downloadApiWithTransformer(DAY_BOOK_DETAIL_CONFIG.domain, type, query),
};

// customer: 4, vendor 6
const ledgerGroupApi = {
  getLedgerGroupList: (query = {}) => apiService.getPaginatedApiWithTransformer('ledgers/groups/list', query),
  createLedgerGroup: (query) => apiService.postApiWithTransformer('ledgers/groups', query),
  deleteLedgerGroup: (query) => apiService.postApiWithTransformer('ledgers/groups/disable', query),
  getAssignedList: (type, query) => apiService.getApiWithTransformer('ledgers/groups/assigned-ledger-list', query),
  updateLedgerGroup: ({ id, query }) => apiService.putApiWithTransformer('ledgers/groups', id, query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(AGGREGATE_LEDGER_REPORT_CONFIG.domain, type, query),
  downloadDetailList: ({ type, query, queryString = '' }) =>
    apiService.downloadApiWithTransformer(AGGREGATE_REPORT_JV_DETAILS_CONFIG.domain, type, query, queryString),
};

const ledgerApi = {
  getCustomerList: (type, query) => apiService.getApiWithTransformer(`ledgers/list?type=${LEDGERS.CUSTOMER.type},${LEDGERS.VENDOR.type}`, query),
  getLedgerList: (type, query) => apiService.getApiWithTransformer(`ledgers/list?type=${LEDGERS.CUSTOMER.type},${LEDGERS.VENDOR.type}`, query),
  getVendorList: (query) => apiService.getApiWithTransformer(`ledgers/list?type=${LEDGERS.VENDOR.type}`, query),
  getAllLedgerList: (query) => apiService.getApiWithTransformer('ledgers/list', query),
  getList: (query = {}, extraQuery = '') =>
    apiService.getPaginatedApiWithTransformer('ledgers/list', query, extraQuery),
  create: (query) => apiService.postApiWithTransformer('ledgers', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('ledgers', id, query),
  upload: (query) => apiService.multipartPostWithTransformer('ledgers/upload', query),
  getReport: (query) => apiService.getPaginatedApiWithTransformer('ledgers/balance', query),
  getReportDetails: ({ queryParameters, extraQuery = '' }) =>
    apiService.getPaginatedApiWithTransformer('ledgers/balance/details', queryParameters, extraQuery),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(LEDGER_LIST_CONFIG.domain, type, query),
  detailDownloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(LEDGER_LIST_CONFIG.domain, type, query, extraQueryString),
  reportDownloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(LEDGER_REPORT_CONFIG.domain, type, query),
  ledgerDetailDownloadList: ({ type, query, extraQuery }) =>
    apiService.downloadApiWithTransformer(LEDGER_DETAILS_REPORT_CONFIG.domain, type, query, extraQuery),
  getAccountGroupList: (query) => apiService.getApiWithTransformer('account-group/last-child', query),
  getOutstandingValue: (id) => apiService.getApiWithTransformer(`ledgers/getOutstandingValue/${id}`),
  checkPanExist:(ledgers)=>apiService.getApiWithTransformer(`ledgers/validate-pan?pan=${ledgers.panNo}&id=${ledgers.customerId}`),
};

const stockLedgerApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('stock-ledgers/paginated-list', query),
  getDetails: ({ query, extraQuery }) =>
    apiService.getPaginatedApiWithTransformer('stock-ledgers/sku', query, extraQuery),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(STOCK_LEDGER_CONFIG.domain, type, query),
  update: (body) => apiService.putApiWithoutIDTransformer('stock-ledgers/update-rate', body),
  downloadDetail: ({ type, query }) =>
    apiService.downloadApiWithTransformer(STOCK_LEDGER_DETAIL_CONFIG.domain, type, query),
  downloadSkuDetail: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(STOCK_LEDGER_DETAIL.domain, type, query, extraQueryString),
};

const stockValuationReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('stock-ledgers/paginated-list/stock-valuation', query),
  getListUpdate: ({ query,extraQuery }) => apiService.getPaginatedApiWithTransformer('stock-ledgers/paginated-list/stock-valuation-update', query,extraQuery),
  downloadList: ({ type, query,extraQueryString }) =>
    apiService.downloadApiWithTransformer(STOCK_VALUATION_REPORT_CONFIG.domain, type, query,extraQueryString),
  downloadDamageExpiry: ({ type, query }) =>
    apiService.downloadApiWithTransformer(DAMAGE_EXPIRY_VALUATION_CONFIG.domain, type, query,),
};

const stockJournalApi = {
  getDetailList: (query) => apiService.getPaginatedApiWithTransformer('stock-journal-details/paginated-list', query),
  getList: (query) => apiService.getPaginatedApiWithTransformer('stock-journals/paginated-list', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(STOCK_JOURNAL_CONFIG.domain, type, query),
  getDetails: (query) => apiService.getApiWithTransformer('stock-journals/detail?', query),
  create: (body) => apiService.postApiWithTransformer('stock-journals', body),
};

const businessGroupApi = {
  getList: ({ query = {}, extraQuery }) =>
    apiService.getPaginatedApiWithTransformer('business-company', query, extraQuery),
  create: (query) => apiService.postApiWithTransformer('business-company/', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('business-company', id, query),
};

const usersApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('users/list', query),
  create: (query) => apiService.postApiWithTransformer('auth/register', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('users', id, query),
};

const agentApi = {
  getAgentList: (query) => apiService.getPaginatedApiWithTransformer('agents/list', query),
  create: (query) => apiService.postApiWithTransformer('agents/create', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('agents/update', id, query),
  getList: (query) => apiService.getApiWithTransformer('agents/list', query),
  updateStatus: ({ id, query }) => apiService.putApiWithTransformer('agents/toggle-status', id, query),
};
const areaApi = {
  getAreaList: (query) => apiService.getPaginatedApiWithTransformer('area', query),
  create: (query) => apiService.postApiWithTransformer('area', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('area', id, query),
  updateStatus: ({ id, query }) => apiService.putApiWithTransformer('area/toggle', id, query),
};

const accountGroupApi = {
  accountGroupList: (query) => apiService.getPaginatedApiWithTransformer('account-group/list', query),
  create: (query) => apiService.postApiWithTransformer('account-group/create', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('account-group/update', id, query),
  getList: (query) => apiService.getApiWithTransformer('account-group/list', query),
};
const catalogsApi = {
  getSKUList: ({ query, extraQuery }) => apiService.getPaginatedApiWithTransformer('skus/list', query, extraQuery),
  getBrandList: (query) => apiService.getApiWithTransformer('catalogs/list?type=brand&', query),
  getSkuUnderBrand: ({ brand_id: brandId, ...query }) =>
    apiService.getApiWithTransformer(`skus/list?business_catalog_detail_id=${brandId}&`, query),
  upload: (query) => apiService.multipartPostWithTransformer('catalogs/skus/upload', query),
  createSkuBatch: (query) => apiService.postApiWithTransformer('catalogs/skus/batch', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(SKU_CONFIG.domain, type, query),
  getBatchAvailableStock: (query) => apiService.getApiWithTransformer('stock-ledgers/batch/detail?', query),
  getSkuList: ({ business_id: buId, ...query }) =>
    apiService.getApiWithTransformer(`skus/list?business_id=${buId}`, query),
  getSkuUnderOtherBUList: ({ business_id: buId, ...query }) =>
    apiService.getApiWithTransformer(`skus/list?business_id=[${buId}]`, query),
};

const skuDetailsApi = {
  getSkuDetails: ({ idSku }) => apiService.getApiWithTransformer(`skus/details?id_sku=${idSku}`),
  getCatalogDetails: ({ catalogId, catalogDetailId, businessId }) =>
    apiService.getApiWithTransformer(
      `catalogs/children?business_id=${businessId}&business_catalog_detail_id=${catalogDetailId}`,
    ),
  createSKUWithBatchList: (body) => apiService.postApiWithTransformer('skus/batch-list', body),
  updateSKUWithBatchList: (body) => apiService.postApiWithTransformer('skus/batch-list-update', body),
  addCatalogs: (query) => apiService.postApiWithTransformer('catalogs/catalog-detail', query),
  uomList: () => apiService.getApiWithTransformer('skus/uoms'),
};

const openingBalanceApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('opening-balances/list', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(OPENING_BALANCE_CONFIG.domain, type, query),
  upload: (query) => apiService.multipartPostWithTransformer('opening-balances/upload', query),
  update: (body) => apiService.putApiWithoutIDTransformer('opening-balances/update',body),
  sampledownloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(SAMPLE_OPENING_BALANCE_CONFIG.domain, type, query),
};

const fiscalYearApi = {
  getList: (query) => apiService.getApiWithTransformer('fiscal-years/list', query),
  getPaginatedList: (query) => apiService.getPaginatedApiWithTransformer('fiscal-years/list', query),
  getMigrateList: (query) => apiService.getApiWithTransformer('fym/migrate-ledger/balance?', query),
  confirm: (query) => apiService.postApiWithTransformer('fym/createJvEntry', query),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(FISCAL_YEAR_MIGRATION_CONFIG.domain, type, query, extraQueryString),
};

const login = {
  userLogin: (query) => apiService.postApiWithTransformer('auth/login', query),
  changePassword: ({ id, query }) => apiService.putApiWithTransformer('users/change-password', id, query),
  forgetPassword: (query) => apiService.postApiWithTransformer('reset-password', query),
  resetPassword: ({ query }) => apiService.putApiWithoutIDTransformer('reset-password', query),
};

const grn = {
  create: (query) => apiService.postApiWithTransformer('purchase', query),
  getList: (query) => apiService.getPaginatedApiWithTransformer('purchase/paginated-list', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(GRN_CONFIG.domain, type, query),
  getDetails: (id) => apiService.getApiWithTransformer(`purchase/detail?id=${id}`),
  find: (purchase_invoice_number) => apiService.getApiWithTransformer(`purchase/find?purchase_invoice_number=${purchase_invoice_number}`),
};

const srn = {
  getList: ({ query }) => apiService.getPaginatedApiWithTransformer('sales-returns/paginated-list', query),
  create: (body) => apiService.postApiWithTransformer('sales-returns', body),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(SRN_CONFIG.domain, type, query),
  detailDownloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(SRN_DETAILS_CONFIG.domain, type, query),
  getDetails: (id) => apiService.getApiWithTransformer(`sales-returns/detail?id=${id}`),
  getPrintDetails: (srnInvoiceNumber) =>
    apiService.getApiWithTransformer(`sales-returns/print-detail?sales_return_invoice_number=${srnInvoiceNumber}`),
  getAvailableSales: (id) => apiService.getApiWithTransformer(`sales-returns/available-list?invoice_number=${id}`),
};

const prn = {
  create: (body) => apiService.postApiWithTransformer('purchase-returns', body),
  getList: (query) => apiService.getPaginatedApiWithTransformer('purchase-returns/paginated-list', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(PRN_CONFIG.domain, type, query),
  getDetails: (id) => apiService.getApiWithTransformer(`purchase-returns/detail?id=${id}`),
  getPrintDetails: (prnInvoiceNumber) =>
    apiService.getApiWithTransformer(`purchase-returns/print-detail?purchase_return_invoice_number=${prnInvoiceNumber}`),
  getAvailablePurchase: (id) => apiService.getApiWithTransformer(`purchase-returns/available-list?invoice_number=${id}`),
  detailDownloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(PRN_DETAILS_CONFIG.domain, type, query),
};

const jv = {
  getList: (query, extraQuery = '') =>
    apiService.getPaginatedApiWithTransformer('reports/journal-summary', query, extraQuery),
  getDetails: (query) => apiService.getApiWithTransformer('reports/journal-detail?', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(JV_CONFIG.domain, type, query),
  detailDownloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(JV_DETAIL_CONFIG.domain, type, query),
  create: (body) => apiService.postApiWithTransformer('journal-vouchers', body),
  update: ({ id, query }) => apiService.putApiWithTransformer('journal-vouchers', id,query),
};

const payment = {
  getReceiptList: ({ query, extraQueryString }) =>
    apiService.getPaginatedApiWithTransformer('payments', query, extraQueryString),
  getOutstandingInvoices: (body) => apiService.postApiWithTransformer('payments/list-outstanding', body),
  createReceipt: (body) => apiService.postApiWithTransformer('payments', body),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(DOMAIN.PDC, type, query),
  paymentDownloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(DOMAIN.PAYMENT, type, query, extraQueryString),
  update: ({ id, query }) => apiService.putApiWithTransformer('payments/update', id, query),
  updateReconcile: ({ id, query }) => apiService.putApiWithTransformer('payments/reconciled', id, query),
  reconcilePayment: (body) => apiService.postApiWithTransformer('payments/map/invoices', body),
  deletePayment: ({ id, query }) => apiService.deleteApiWithTransformer('payments/deletePayment', id, query),
};

const checkIfApiCallSuccess = (res) => !!res.success;

const withApiResponseProcessor = (apiCall) => {
  apiCall.then((response, err) => {
    if (response.status === 200) {
      return response;
    }

    throw err;
  });
};

const ageingReportApi = {
  getList: ({ query, extraQueryString }) =>
    apiService.getPaginatedApiWithTransformer('reports/ageing', query, extraQueryString),
  getList1: (query) =>
    apiService.getPaginatedApiWithTransformer('reports/ageing', query, '&report_date=2020-12-28&slab_no=7'),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer('ageing-report', type, query, extraQueryString),
};
const stockAgeingReportApi = {
  getList: ({ query, extraQueryString }) =>
    apiService.getPaginatedApiWithTransformer('reports/stock-aging', query, extraQueryString),
  getList1: (query) =>
    apiService.getPaginatedApiWithTransformer('reports/stock-aging', query, '&report_date=2019-09-22&slab_no=2'),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer('stock_aging_report', type, query, extraQueryString),
};

const outStandingReportApi = {
  getList: ({ query, extraQueryString }) =>
    apiService.getPaginatedApiWithTransformer('reports/outstanding', query, extraQueryString),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(DOMAIN.OUTSTANDING_REPORT, type, query, extraQueryString),
};

const netSalesReportApi = {
  getList: ( queryParameters ) =>
    apiService.getPaginatedApiWithTransformer('reports/netSales', queryParameters),
  downloadList: ( { type, query }) =>
    apiService.downloadApiWithTransformer(DOMAIN.NET_SALES, type, query),

};

const salesPersonOutstandingApi = {
  getList: ({ query, extraQueryString }) =>
    apiService.getPaginatedApiWithTransformer('/reports/agent/outstanding',query,extraQueryString),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(DOMAIN.SALES_PERSON_OUTSTANDING_REPORT, type, query, extraQueryString),
}

const databaseApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/database-log', query),
  download: ({ query }) => apiService.downloadApi(DATABASE_CONFIG.domain, REPORT_TYPE.XLS, query),
};

const salesVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/sales-vat', query),
  downloadList: ({ type, query, extraString, domain }) =>
    apiService.downloadApiWithTransformer(domain, type, query, extraString),
};
const newSalesVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/new-sales-vat', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(NEW_SALES_VAT_REPORT_CONFIG.domain, type, query),
};
const srnVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/srn-vat', query),
  downloadList: ({ type, query, extraString, domain }) =>
    apiService.downloadApiWithTransformer(domain, type, query, extraString),
};
const newSrnVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/new-srn-vat', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(NEW_SRN_VAT_REPORT_CONFIG.domain, type, query),
};

const vatReportApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/vat', query),
  downloadList: ({ type, query, extraString, domain }) =>
    apiService.downloadApiWithTransformer(domain, type, query, extraString),
};
const vatSummaryReportApi = {
  getList: ({ query, extraQuery }) =>
    apiService.getPaginatedApiWithTransformer('reports/vat-summary', query, extraQuery),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(VAT_SUMMARY_REPORT_CONFIG.domain, type, query, extraQueryString),
};
const purchaseReturnVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/purchase-vat-return', query),
  downloadList: ({ type, query, extraString, domain }) =>
    apiService.downloadApiWithTransformer(domain, type, query, extraString),
};
const purchaseVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/purchase-vat', query),
  downloadList: ({ type, query, extraString, domain }) =>
    apiService.downloadApiWithTransformer(domain, type, query, extraString),
};
const newPurchaseVatApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/new-purchase-vat', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(NEW_PURCHASE_VAT_REPORT_CONFIG.domain, type, query),
};
const newPurchaseVatReturnApi = {
  getList: (query) => apiService.getPaginatedApiWithTransformer('reports/new-purchase-vat-return', query),
  downloadList: ({ type, query }) =>
    apiService.downloadApiWithTransformer(NEW_PURCHASE_RETURN_VAT_REPORT_CONFIG.domain, type, query),
};

const filterInitApi = {
  getBrandList: (query) => apiService.getApiWithTransformer('catalogs/list?type=brand&', query),
  getBuList: (query) => apiService.getApiWithTransformer('business-company', query),
  getFiscalYearList: fiscalYearApi.getList,
  getAgentList: agentApi.getList,
  getAccountGroupList: accountGroupApi.getList,
};

const trialBalanceApi = {
  getTrialList: (query) => apiService.getPaginatedApiWithTransformer('reports/trial-balance-update', query),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(TRIAL_BALANCE_CONFIG.domain, type, query, extraQueryString),
};

const profitLossApi = {
  getProfitLossList: (query) => apiService.getPaginatedApiWithTransformer('reports/profit-loss', query),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(PROFIT_LOSS_CONFIG.domain, type, query, extraQueryString),
};

const balanceSheetApi = {
  getBalanceSheetList: (query) => apiService.getPaginatedApiWithTransformer('reports/balance-sheet', query),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(BALANCE_SHEET_CONFIG.domain, type, query, extraQueryString),
  getStartDate :({ query, extraQueryString }) => apiService.getPaginatedApiWithTransformer('fiscal-years/report/start-date',query,extraQueryString),
};

const tagApi = {
  getTagList: (query) => apiService.getPaginatedApiWithTransformer('tags/getlist', query),
  create: (query) => apiService.postApiWithTransformer('tags/create', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('tags/update', id, query),
  deleteTag: ({ id, query }) => apiService.deleteApiWithTransformer('tags/delete', id, query),
  getList: (query) => apiService.getApiWithTransformer('tags/getlist', query),
};

const pickListApi = {
  getPickList: (query) => apiService.getPaginatedApiWithTransformer('sales-invoices/getPickList', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer(PICK_LIST_CONFIG.domain, type, query),
  downloadSalesSummary: ({ type, query }) =>
    apiService.downloadApiWithTransformer(SALES_SUMMARY_CONFIG.domain, type, query,),
};
const userGroupApi = {
  getUserGroupList: (query) => apiService.getPaginatedApiWithTransformer('user-group/', query),
  createUserGroup: (query) => apiService.postApiWithTransformer('user-group/', query),
  getDetails: (id) => apiService.getApiWithTransformer(`user-group/ ${id}`),
  updateDescription: ({ id, query }) => apiService.putApiWithTransformer('user-group', id, query),
  updatePermission: ({ id, query }) => apiService.putApiWithTransformer('user-group/toggle-permission', id, query),
};

const promotionApi = {
  getPromotionList: (query) => apiService.getPaginatedApiWithTransformer('promotion/getList', query),
  create: (query) => apiService.postApiWithTransformer('promotion/create', query),
  update: ({ id, query }) => apiService.putApiWithTransformer('promotion/update', id, query),
  getPromotionDetails: (id) => apiService.getApiWithTransformer(`promotion/getbyid/${id}`),
  getApplicablePromotions: (query) => apiService.getApiWithTransformer('promotion/applicable-promotions?', query),
  validatePromotion: ({ id, query }) => apiService.postApiWithTransformer(`promotion/validate-line/${id}`, query),
};

const tagReportApi = {
  getLedgerWiseTag: (query) => apiService.getPaginatedApiWithTransformer('reports/ledger-wise-tag', query),
  getTagWiseLedger: (query) => apiService.getPaginatedApiWithTransformer('reports/tag-wise-ledger', query),
  getTagDetailReport: ({ queryParameters, extraQuery }) => apiService.getPaginatedApiWithTransformer('reports/tag/detail', queryParameters, extraQuery),
  downloadList: ({ type, query, extraQueryString }) =>
    apiService.downloadApiWithTransformer(TAG_WISE_DETAILS_REPORT_CONFIG.domain, type, query, extraQueryString),
}

const debitNoteAPi = {
  getList: (query, extraQuery = '') =>
    apiService.getPaginatedApiWithTransformer('reports/journal-summary', query, extraQuery),
  getDetails: (query) => apiService.getApiWithTransformer('reports/journal-detail?', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer('debit_note', type, query),
  create: (body) => apiService.postApiWithTransformer('journal-vouchers', body),
  update: ({ id, query }) => apiService.putApiWithTransformer('journal-vouchers', id,query),
};

const creditNoteAPi = {
  getList: (query, extraQuery = '') =>
    apiService.getPaginatedApiWithTransformer('reports/journal-summary', query, extraQuery),
  getDetails: (query) => apiService.getApiWithTransformer('reports/journal-detail?', query),
  downloadList: ({ type, query }) => apiService.downloadApiWithTransformer('credit_note', type, query),
  create: (body) => apiService.postApiWithTransformer('journal-vouchers', body),
  update: ({ id, query }) => apiService.putApiWithTransformer('journal-vouchers', id,query),
};

export {
  withApiResponseProcessor,
  checkIfApiCallSuccess,
  login as loginApi,
  grn as grnApi,
  srn as srnApi,
  prn as prnApi,
  jv as jvApi,
  payment as paymentApi,
  debitNoteAPi,
  creditNoteAPi,
  buApi,
  salesMVApi,
  ledgerApi,
  catalogsApi,
  salesInvoiceApi,
  entryLogApi,
  stockLedgerApi,
  stockJournalApi,
  businessGroupApi,
  companyApi,
  ageingReportApi,
  stockAgeingReportApi,
  outStandingReportApi,
  salesPersonOutstandingApi,
  openingBalanceApi,
  fiscalYearApi,
  usersApi,
  databaseApi,
  salesVatApi,
  newSalesVatApi,
  filterInitApi,
  stockValuationReportApi,
  skuDetailsApi,
  srnVatApi,
  newSrnVatApi,
  purchaseReturnVatApi,
  purchaseVatApi,
  newPurchaseVatApi,
  newPurchaseVatReturnApi,
  vatReportApi,
  vatSummaryReportApi,
  ledgerGroupApi,
  dayBookSummaryApi,
  agentApi,
  areaApi,
  accountGroupApi,
  trialBalanceApi,
  profitLossApi,
  balanceSheetApi,
  tagApi,
  pickListApi,
  promotionApi,
  userGroupApi,
  salesReportApi,
  srnReportApi,
  prnReportApi,
  grnReportApi,
  tagReportApi,
  netSalesReportApi
};
