import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import tableConfig from './config';
import * as queryService from '../../../common/query.service';
import PageView from '../../../common/pagination/PageView';
import withBaseState from '../../../common/withBaseState';
import * as filterUtil from '../../../../utils/filterUtil';
import { ACTION } from '../../../../data/enums/enums';
import { debouncer } from '../../../../utils/handlers';
import Dialog from './Dialog';
import { DOMAIN, REPORT_TYPE } from '../../../../data/enums/enums';
import { BillingSnackBar ,Button} from '../../../../components/BillingMDC';
import * as downloadService from '../../../../views/common/download.service';
import * as snackService from '../../../common/snack.service';
import StockLedgerStyled from '../StockLedgerStyled';

const propTypes = {
  stockDetails: PropTypes.shape({
    detail: {
      name: PropTypes.string,
      ledgerId: PropTypes.number,
      debit: PropTypes.number,
      credit: PropTypes.number,
      openingBalance: PropTypes.number,
      closingBalance: PropTypes.number,
    },
    date: {
      start: PropTypes.string,
      end: PropTypes.string,
    },
  }),
  getStockLedgerDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  stockDetails: {
    detail: {
      name: '',
      ledgerId: null,
      debit: null,
      credit: null,
      openingBalance: null,
      closingBalance: null,
    },
    date: {
      start: filterUtil.getCurrentDay(),
      end: filterUtil.getCurrentDay(),
    },
  },
};

class StockLedgerDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      data: {
        list: [],
        total: 0,
      },
      snack: { ...snackService.snackParameters },
      readModalOpen:false,
      dialogType: ACTION.NULL,
      activeId:null,
      dataList:[],
      queryParameters: {
        ...queryService.queryParameters,
      },
    };
    const { downloadSkuDetail } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadSkuDetail,
    );
    this.basePaginationService.resetFilter();
  }

  static getDerivedStateFromProps(props, state) {
    const { stockDetails } = props;
    const { queryParameters } = state;
    const { date } = stockDetails;

    return {
      queryParameters: {
        ...queryParameters,
        date,
      },
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { stockDetails, getDetails } = this.props;
    const { queryParameters } = this.state;
    const { row } = stockDetails;
    const extraQuery = `&sku_id=${row.skuId}&bu_id=${row.bu.id}`;
    getDetails({ query: queryParameters, extraQuery }).then(response => {
      const { data } = this.state;
      data.list = response;
      data.total = response.total ? response.total : response.length;
      this.setState({ data });
    });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleDownloadClick = reportType => {
    const { downloadSkuDetail, stockDetails } = this.props;
    const { queryParameters } = this.state;
    const { row } = stockDetails;
    downloadSkuDetail({
      type: reportType,
      query: queryParameters,
      extraQueryString: `&sku_id=${row.skuId}&bu_id=${row.bu.id}`,
    }).then(response => downloadService.resolver(response));
  };

  getDataList = (element) => {
    const {dataList} =this.state;
    const filteredData = tableConfig?.getPayloadUpdate(element)
    dataList.push(filteredData);
    this.setState({dataList});
  }

  handleInputChange = (value, field,element,index) => {
    const {data} =this.state;
    if(field ==="perUnitRate"){
    data.list[index].per_unit_rate = value;
    }
    this.setState({ data},()=>{
      if (field === 'perUnitRate') {
        debouncer(this.getDataList, 1000)(element);
      }
    });
  };
  handleTableBodyClick = (type,element) =>{
    if(type === ACTION.UPDATE ){
      this.setState({ dialogType: ACTION.UPDATE,activeId:element?.id });
    }
  }
  getUniqueDatalList = () => {
    const {dataList} =this.state;
    const uniqueList = [
      ...new Map(dataList.map((item) => [item["id"], item])).values(),
  ];
  return uniqueList;
  }
  getUpdate = (filteredData) => {
    const { update } = this.props;
    update(filteredData).then(response => {
      const snack = snackService.generateSuccessMessage('Per unit rate is successfully updated.');
      this.setState({ snack,dataList:[]},()=>{
        this.onCancel();
      });
    })
    .catch(err => {
      const snack = snackService.generateFailureMessage(err || 'Error while updating per unit rate.');
      this.setState({ snack});
    });
  };
  onModalSubmit = () =>{
    const filteredList =this.getUniqueDatalList();
    this.getUpdate(filteredList);
  }
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  onSave= () => {
    const {dataList} =this.state;
    if(dataList?.length > 0){
      this.setState({ dialogType: ACTION.READ ,readModalOpen:true});
      return;
    }
      this.onModalClose();
    }
  onCancel = () =>{
    this.onModalClose();
    this.loadTableData();
  }
  onModalClose =() =>{
    this.setState({ dialogType: ACTION.NULL ,readModalOpen:false})
  }

  render() {
    const { data, display, queryParameters ,dialogType,activeId,readModalOpen,snack} = this.state;

    const { serverResponseWaiting, stockDetails } = this.props;
    const { row } = stockDetails;
    return (
      <StockLedgerStyled className={`stock-ledger ledger-report details ${dialogType ===ACTION.UPDATE && 'update'}`}>
        <PageView
          domainConfig={{
            title: row.title,
            downloadList: [REPORT_TYPE.CSV, REPORT_TYPE.PDF],
            domain: DOMAIN.STOCK_LEDGER_DETAIL,
          }}
          headerConfig={{
            search: false,
            date: true,
            download: true,
            filter: true,
            create: false,
          }}
          footerConfig={{
            pagination: true,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting}
          handleSearchChange={this.basePaginationService.handleSearchInputChange}
          clearSearchText={this.basePaginationService.clearSearchText}
          resetFilter={this.basePaginationService.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.handleDownloadClick}
          data={data}
          onInputChange={this.handleInputChange}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          dialogType={dialogType}
          activeIndex={activeId}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
        />

        { dialogType !==  ACTION.UPDATE &&
          <Dialog
            actionType={dialogType}
            readModalOpen={readModalOpen}
            onModalSubmit={this.onModalSubmit}
            onModalClose ={this.onModalClose}
          />
          }
          { dialogType ===  ACTION.UPDATE &&
            <div className="btns">
            <Button accent className='mdc-button dialog-cancel cancel-btn modal-btn'  onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button
              accent
              className='mdc-button save-btn modal-btn'
              onClick={() => this.onSave()}
            >
              Save
            </Button>
          </div>
        }
            <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </StockLedgerStyled>
    );
  }
}

StockLedgerDetail.propTypes = propTypes;

StockLedgerDetail.defaultProps = defaultProps;

const mapStateToProps = state => ({
  stockDetails: state.stockLedgerData || {},
});

const stockLedgerDetail = connect(mapStateToProps)(StockLedgerDetail);

const StockLedgerDetailWithState = withBaseState(stockLedgerDetail);

export default StockLedgerDetailWithState;
